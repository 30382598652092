/* eslint-disable camelcase */
'use strict';

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable consistent-return */

import urlParse from 'url-parse';
import get from 'lodash/get';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import assign from 'lodash/assign';
import replace from 'lodash/replace';
import mean from 'lodash/mean';
import last from 'lodash/last';
import every from 'lodash/every';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import unionBy from 'lodash/unionBy';
import {MATOMO_ANALYTICS} from '@techsee/techsee-common/lib/analytic-scripts/matomo-analytics';
import {bulkEmailSharingModeEnum} from '@techsee/techsee-common/lib/constants/account.constants.js';
import {deviceSets} from '@techsee/techsee-common/lib/constants/smart.constants';
import {countryCodes} from '@techsee/techsee-country-codes/lib/index';

import {
    UserType,
    ReasonForClosing,
    AddressTypesEnum,
    MeetingMode,
    FailureTriggerAction,
    UserInteractionTriggerAction,
    PhotoStreamReasonKeys,
    RECORDING_STATUS,
    AppLauncherTypesEnum,
    SESSION_SOURCE
} from '@techsee/techsee-common/lib/constants/room.constants';
import {InviteStartedFrom, VersionStatus, PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import utils from '@techsee/techsee-common/lib/utils';
import {senderTypes} from '@techsee/techsee-common/lib/constants/resource.constants';
import {ConnectionStatesEnum} from '../../_react_/services/Session/SessionContracts';

// Dialog controller and view
import timeoutDialogView from './dialogs/timeout.view.html';
import {TimeoutDialogController} from './dialogs/timeout.controller.js';
import clientTimeoutDialogView from './dialogs/client-timeout.view.html';
import {ClientTimeoutDialogController} from './dialogs/client-timeout.controller.js';
import surveyView from './dialogs/survey.view.html';
import {SurveyController} from './dialogs/survey.controller.js';
import thankYouView from '../dashboard/dialogs/thank-you.view.html';
import endMeetingDialogView from './dialogs/end-meeting.view.html';
import {EndMeetingDialogController} from './dialogs/end-meeting.controller.js';

import {
    AGENT_QOS_LOG_INTERVAL,
    API_TO_HINTER_MAP,
    BANDWIDTH_SAMPLES_BUFFER_MAX_SIZE,
    OBSERVATION_LOG_INTERVAL,
    SENDING_TIMEOUT_INTERVAL,
    ENDING_TIMEOUT,
    SURVEY_CONSTANTS,
    LOCATION_ADDRESS_STATUS,
    LOCATION_ICONS,
    VIDEO_BANDWIDTH_SAMPLE_COUNT_DURING_SESSION,
    VIDEO_DURATION_LOG_INTERVAL,
    VIDEO_TRAFFIC_LOG_INTERVAL,
    MEDIA_SERVICE,
    RECORDING_FAILED_TIMEOUT,
    RECORDING_ERROR_COLOR,
    RECORDING_MESSAGE_ALERT_TIMEOUT,
    OBSERVER_JOIN_ALERT_TIMEOUT,
    OBSERVER_ALERT_COLOR,
    USER_REDIRECTED_TIMEOUT,
    POINTER_UNAVAILABLE_MESSAGE_ALERT_TIMEOUT,
    POINTER_UNAVAILABLE_MESSAGE_ALERT_COLOR,
    DS_CONTROL_REQUEST_APPROVED_COLOR,
    DS_CONTROL_REQUEST_APPROVED_TIMEOUT,
    DS_CONTROL_STOPPED_COLOR,
    DS_CONTROL_STOPPED_TIMEOUT
} from './dashboard.settings';

import {CONNECTION_STATUS} from '../../services/ts-locale-helper/ts-locale-helper.settings.js';
import {DIALOG_TYPES} from './main/dialogs/generic-dialog.settings';
import {getRootStore} from '../../_react_/app.bootstrap';
import {RedirectToStoreController} from './redirectToStoreGuidance/redirectToStore.controller';
import {FallbackModeController} from '../../_react_/components/fallback-mode/controller';
import {updateFallbackModesNotification} from '../../_react_/components/fallback-mode/helper';
import {ModeSwitchModalController} from '../../_react_/components/mode-switch-confirmation-modal/controller';
import {DashboardGuidanceController} from '../../_react_/states/dashboard/dashboard-guidance/controller';
import {DashboardTopBarController} from '../../_react_/states/dashboard/dashboard-top-bar/controller';
import {DashboardFooterController} from '../../_react_/states/dashboard/footer/controller';
import {DashboardLeftBarController} from '../../_react_/states/dashboard/left-bar/controller';
import {
    SpinnerBackgroundColors,
    SpinnerColors,
    SpinnerSizes,
    SpinnerTypeOptions
} from '@techsee/techsee-ui-common/lib/spinner';
import {runInAction} from 'mobx';
import {NetworkInfo} from '../../_react_/models/LiveSessionState';
import {SessionClientType, SessionClientRole} from '@techsee/techsee-media-service/lib/MediaConstants';
import {SelfServiceController} from '../../_react_/states/dashboard/self-service/controller';
import {MeetingStateEvents} from './dashboard.contracts';
import {getAppTracer} from '../../app.tracer';
import {MediaStatsDataCollector} from '../../services/media-service-api/MediaStatsDataCollector';
import {stateByMode} from '@techsee/techsee-common/lib/constants/meeting.states.definition';
import {onSocketVersionHandler} from '../../_react_/services/Session/SessionEventHandler';
import {SimpleModalController} from '../../_react_/components/simple-modal/controller';
import {MessageType} from '../../_react_/services/JsApiService';
import {
    BandwidthMeasurementService,
    BandwidthLevel,
    BANDWIDTH_LAST_SAMPLE
} from '../../services/ts-bandwidth-measurement/ts-bandwidth-measurement.service';
import {openLinkInNewTab} from '../../_react_/states/helper/general.helper.ts';
import {CopilotService} from '../../_react_/services/CopilotService';
import {accountTypes} from '@techsee/techsee-common/lib/constants/account.constants';

const trace = getAppTracer('DashboardController');

export class DashboardController {
    // eslint-disable-next-line complexity
    constructor(
        $window,
        $state,
        dashboardState,
        tsStateHelper,
        $scope,
        $stateParams,
        $localStorage,
        $q,
        $modal,
        currentUser,
        accountData,
        roomData,
        db,
        tsChatApi,
        tsCanvasAnnotate,
        tsVideoControl,
        meetingHistory,
        tsLocaleHelper,
        eventLog,
        qos,
        tsBusy,
        tsTimedOperation,
        tsUrlUtils,
        $translate,
        $timeout,
        wizardState,
        LogEvents,
        $rootScope,
        tsHelpBrowser,
        tsWizardHelper,
        preloadedImage,
        preloadedVideo,
        ROLES,
        auth,
        $interval,
        tsUrlConfig,
        tsVisibilityChange,
        STATUS_MESSAGES,
        ErrorDialogModal,
        GenericDialogFactory,
        tsInterfaceHelper,
        SharingService,
        allowScreenShare,
        mediaServiceType,
        tsSmsService,
        tsDeviceClassificationService,
        tsSessionSummaryService,
        tsQuickLauncher,
        authExpiry,
        dashboardMediaService,
        AudioService,
        patternsConstant,
        AutoDataCollectionServices
    ) {
        'ngInject';

        trace.info('Constructing dashboard controller');

        this.openHelp = this.openHelp.bind(this);
        this.sendObserverEmail = this.sendObserverEmail.bind(this);
        this.sendObserverSMS = this.sendObserverSMS.bind(this);
        this.openDeviceClassification = this.openDeviceClassification.bind(this);
        this.openModeSwitchConfirmationModal = this.openModeSwitchConfirmationModal.bind(this);
        this.modeSwitchHandleModalResponse = this.modeSwitchHandleModalResponse.bind(this);
        this.openSelfServiceWarmTransferSummary = this.openSelfServiceWarmTransferSummary.bind(this);
        this.endMeeting = this.endMeeting.bind(this);
        this.displayEndMeetingDialog = this.displayEndMeetingDialog.bind(this);
        this.onAbort = this.onAbort.bind(this);
        this.sendImage = this.sendImage.bind(this);
        this.sendResourcesToClient = this.sendResourcesToClient.bind(this);
        this.saveResource = this.saveResource.bind(this);
        this.newTopbarHeaderCopyToClipboard = this.newTopbarHeaderCopyToClipboard.bind(this);
        this.newTopbarHeaderEmail = this.newTopbarHeaderEmail.bind(this);
        this.newTopbarHeaderDownload = this.newTopbarHeaderDownload.bind(this);
        this.saveImageCloud = this.saveImageCloud.bind(this);
        this.leaveOfflineRoom = this.leaveOfflineRoom.bind(this);
        this.onIdleClient = this.onIdleClient.bind(this);
        this.summaryEndSession = this.summaryEndSession.bind(this);
        this.onSubmitObserver = this.onSubmitObserver.bind(this);
        this.onSubmitAppLauncher = this.onSubmitAppLauncher.bind(this);
        this.getInteractionSummaryData = this.getInteractionSummaryData.bind(this);
        this.getDateFormattedByAcc = this.getDateFormattedByAcc.bind(this);

        /* eslint max-params: 0*/
        this.sharingService = SharingService;
        this.autoDataCollectionServices = AutoDataCollectionServices;

        this.summaryService = tsSessionSummaryService;

        this.dashboardState = dashboardState;
        this.dashboardMediaService = dashboardMediaService;

        const wifiAnalyzerService = getRootStore().wifiAnalyzerService;

        if (wifiAnalyzerService) {
            wifiAnalyzerService.setMediaService(dashboardMediaService);
        }

        this.urlUtils = tsUrlUtils;

        this.simpleAPIMode =
            this.urlUtils.getParamValue('p') ||
            this.urlUtils.getParamValue('r') ||
            this.urlUtils.getParamValue('cid') ||
            this.urlUtils.getParamValue('c');
        this.offlineMode = this.urlUtils.getParamValue('offline') === 'true';
        this.returnQuery = this.urlUtils.getParamValue('returnQuery');
        this.roomCode = this.urlUtils.getParamValue('roomCode');

        this.browserUtilsService = getRootStore().browserUtilsService;
        this.miniDashboard = this.browserUtilsService.getFromSessionStorage('miniDashboard');
        this.embeddedDashboard = this.browserUtilsService.getFromSessionStorage('embeddedDashboard');
        this.db = db;
        this.$state = $state;
        this.$window = $window;
        this.stateHelper = tsStateHelper;

        this.chatApi = tsChatApi.service;

        this.visibilityChange = tsVisibilityChange;

        this.STATUS_MESSAGES = STATUS_MESSAGES;

        if (this.simpleAPIMode) {
            return this._handleSimpleAPIMode(
                this.urlUtils.getParamValue('p'),
                this.urlUtils.getParamValue('r'),
                this.urlUtils.getParamValue('cid'),
                this.urlUtils.getParamValue('c')
            );
        }

        this.$localStorage = $localStorage;
        this.$q = $q;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$modal = $modal;
        this.currentUser = currentUser;
        this.accountData = accountData;
        this.roomData = roomData;
        this.observe = $stateParams.observe;
        this.annotateService = tsCanvasAnnotate;
        this.videoControlService = tsVideoControl;
        this.meetingHistory = meetingHistory;
        this.customerNumber = roomData.customerNumber !== 'none' ? roomData.customerNumber : '';
        this.customerId = roomData.customerId !== 'none' ? roomData.customerId : '';
        this.eventLog = eventLog;
        this.qos = qos;
        this.LogEvents = LogEvents;
        this.localeHelper = tsLocaleHelper;
        this.LOCATION_ICONS = LOCATION_ICONS;
        this.bulkEmailSharingModeEnum = bulkEmailSharingModeEnum;
        this.deviceSets = deviceSets;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this.$interval = $interval;
        this.busy = tsBusy;
        this.timedOperation = tsTimedOperation;
        this.dir = tsLocaleHelper.isRTL() ? 'rtl' : 'ltr';
        this.environmentDetect = getRootStore().environmentService;
        this.wizardState = wizardState;
        this.wizardHelper = tsWizardHelper;
        this.helpBrowser = tsHelpBrowser;
        this.preloadedImage = preloadedImage;
        this.preloadedVideo = preloadedVideo;
        this.auth = auth;
        this.urlConfig = tsUrlConfig;
        this.errorDialogModal = ErrorDialogModal;
        this.dialogFactory = GenericDialogFactory;
        this._trafficLogger = null;
        this._videoDurationLogger = null;
        this.offlineRoom = roomData.offline && !this.offlineMode;
        this.clientDisconnected = false;
        this.isLivePointerRunning = false;
        this.tsInterfaceHelper = tsInterfaceHelper;
        this.isFF = this.environmentDetect.isFF();
        this.isIE = this.environmentDetect.isIE11();
        this.isMobile = this.environmentDetect.isMobile(getRootStore().displayTabletAsDesktop);
        this.isTablet = this.environmentDetect.isTablet(false);
        this.isDesktopView = this.environmentDetect.isDesktop();
        this.allowScreenShare = allowScreenShare;
        this.smsService = tsSmsService;
        this.smsService.init(db, get(accountData, 'protectedSettings.smsSettings'), accountData._id, currentUser._id);
        this.enableMobileGeolocation = get(this.accountData, 'protectedSettings.enableMobileGeolocation');
        this.fallbackModeNotification = get(this.accountData, 'protectedSettings.fallbackModeNotification');
        this.enableIdleModeUsability = get(this.accountData, 'protectedSettings.enableIdleModeUsability');
        this.enableReloginOnSessionExpired = get(this.accountData, 'protectedSettings.enableReloginOnSessionExpired');
        this.addCountryCodeToSms = get(this.accountData, 'protectedSettings.smsSettings.addCountryCodeToSms');
        this.enableVideoTimeoutHackForSdk = get(this.accountData, 'protectedSettings.enableVideoTimeoutHackForSdk');

        if (this.addCountryCodeToSms) {
            this.selectedCountries = get(this.accountData, 'protectedSettings.selectedCountries');
            this.accountCountryCode = get(this.accountData, 'settings.countryCode');
            this.countryCodeLoading = false;

            this.$translate('INVITE.VIEW.COUNTRY_CODES_PLACEHOLDER').then((m) => (this.countryCodesPlaceHolder = m));

            this.$translate('INVITE.VIEW.COUNTRY_CODES_NO_MATCH').then((m) => (this.countryCodesNoMatch = m));
        }

        if (this.isCopilotEnabled) {
            this._copilotService = new CopilotService(
                get(this.accountData, 'protectedSettings.copilot.version'),
                this.accountData._id,
                this.currentUser._id
            );
            this.initCopilot = this.initCopilot.bind(this);

            if (this.miniDashboard) {
                this._copilotService.loadSdkAndPlugin(true);
            }
        }

        this.mobilePopoverLocation = '';
        this._sendingTimeout = null;
        this.tsQuickLauncher = tsQuickLauncher;
        this.authExpiry = authExpiry;
        this.selectedResources = {images: [], history: []};
        this.domUtilsService = getRootStore().domUtilsService;
        this.translate = getRootStore().localizationService.translate;
        this.jsApiService = getRootStore().jsApiService;
        this.enableSharingImagesContainer = false;
        this.screenShareIOS = false;
        this.saveOnLocalStorage = utils.isInIframe(window) || (this.miniDashboard && !this.isDesktopView);

        this.audioService = AudioService;

        if (!this.fallbackModeController && this.fallbackModeNotification) {
            this.fallbackModeController = new FallbackModeController(this.translate, this.domUtilsService);
        }

        this.authExpiry.service.init(
            {
                isNotificationEnabled: this.enableReloginOnSessionExpired,
                roomId: this.roomData._id,
                userId: currentUser._id,
                roomUserId: roomData.userId,
                accountId: accountData._id
            },
            () => {
                this.loginModalController = this.authExpiry.service.loginModalController;
            }
        );

        const exposePostMessageApi = get(accountData, 'protectedSettings.exposePostMessageApi');
        const accountSettingsJsApi = {
            timezone: get(accountData, 'settings.timezone'),
            language: get(accountData, 'settings.language')
        };

        if (exposePostMessageApi && exposePostMessageApi.enabled) {
            this.jsApiService.init(this.endMeeting.bind(this), assign(exposePostMessageApi, accountSettingsJsApi));
        }

        const knowledgeExpertCanRunSessions =
            currentUser.role === ROLES.KNOWLEDGE_EXPERT &&
            get(this.accountData, 'protectedSettings') &&
            !this.accountData.protectedSettings.knowledgeExpertRunSessions;

        this.libraryTaggingMode = this.offlineMode && knowledgeExpertCanRunSessions;

        if (!this.offlineMode) {
            this.visibilityChange.visible(() => this.chatApi.visibilityChanged(true));
            this.visibilityChange.hidden(() => this.chatApi.visibilityChanged(false));

            // Report room as started to avoid having the room be reported as connected before it is started
            this.roomData.start();
        }

        this.mediaServiceType = mediaServiceType;

        this.isOpentok = this.mediaServiceType === MEDIA_SERVICE.OPENTOK;
        this.isMediaServer = this.mediaServiceType === MEDIA_SERVICE.MEDIASERVER;

        $translate('DASHBOARD.CONTROLLER.DEFAULT_CUSTOMER_NAME').then((m) => (this.defaultCustomerName = m));

        // Init event log service
        this.eventLog.room = roomData._id;
        this.eventLog.userId = this.currentUser._id;

        if (!this.accountData.settings.logoUrl) {
            this.accountData.settings.logoUrl = $scope.requireImage('new-logo.png');
        }

        this.agentHasRemoteControl = false;
        this.interactionSummaryTranslated = getRootStore().interactionSummaryTranslated;
        this.enableShareMenu = get(this.accountData, 'protectedSettings.enableShareMenu');
        this.copyToClipboard = get(this.accountData, 'protectedSettings.enableCopyToClipboard');
        this.localImageSaving = get(this.accountData, 'protectedSettings.localImageSaving');
        this.emailImageSharing = get(this.accountData, 'protectedSettings.emailImageSharing');
        this.quickSharingModesEnabled = get(this.accountData, 'protectedSettings.quickSharingModesEnabled');
        this.allowSessionSummary =
            !this.isIE &&
            !this.offlineMode &&
            !this.isMobile &&
            get(this.accountData, 'protectedSettings.enableSessionSummary') &&
            get(this.accountData, 'protectedSettings.enableHistory') &&
            (get(this.accountData, 'protectedSettings.saveSnapshots') ||
                get(this.accountData, 'protectedSettings.autosaveSharedImages'));
        this.allowNewSessionSummary =
            this.allowSessionSummary &&
            get(this.accountData, 'protectedSettings.newSessionSummary.enable') &&
            get(this.accountData, 'protectedSettings.newSessionSummary.allowAddingDescriptionAndNewTags');
        this.descriptionMaxLength = get(this.accountData, 'protectedSettings.newSessionSummary.descriptionMaxLength');
        this.multipleImagesSharingEnabled =
            this.enableShareMenu && get(this.accountData, 'protectedSettings.multipleImagesSharingEnabled');
        this.multipleImagesSharingQuota =
            this.multipleImagesSharingEnabled && get(this.accountData, 'protectedSettings.multipleImagesSharingQuota');
        this.downloadAndEmailSharing =
            this.enableShareMenu &&
            this.multipleImagesSharingQuota &&
            get(accountData, 'protectedSettings.enabledBulkSharingModes') ===
                this.bulkEmailSharingModeEnum.DOWNLOAD_AND_EMAIL;
        this.enableSelfServiceInLeftBar =
            get(this.accountData, 'protectedSettings.enableSelfServiceInLeftBar') &&
            get(this.accountData, 'protectedSettings.allowSelfServiceOfflineSessions') &&
            get(this.accountData, 'protectedSettings.newHeaderFooterLeftbar');

        this.enableObservation =
            get(this.accountData, 'protectedSettings.enableSilentObserve') &&
            get(this.accountData, 'protectedSettings.observation.enable') &&
            !this.observe &&
            (!isEmpty(this.currentUser.groups) ||
                get(this.accountData, 'protectedSettings.observation.allowGuestObserver'));
        this.allowSendByEmail = get(this.accountData, 'settings.allowSendByEmail');
        this.allowInviteBySms = get(this.accountData, 'settings.allowInviteBySms');
        this.invisibleModeObservation = get(this.accountData, 'protectedSettings.observation.dashboardInvisibleMode');
        this._updateMailTrigger();

        const newSessionSummarySettings = {
            allowNewSessionSummary: this.allowNewSessionSummary,
            descriptionMaxLength: this.descriptionMaxLength
        };

        this.summaryService.init(this.roomData._id, db, accountData.imageTags, newSessionSummarySettings);

        // hide copy to clipboard when resource security is enabled
        this.copySecureLink = get(this.accountData, 'protectedSettings.resourceSecurity');

        this.isLowImageSharingQuality = get(this.accountData, 'protectedSettings.imageSharingQuality') < 1;
        this.isHighImageSharingQuality = get(this.accountData, 'protectedSettings.imageSharingQuality') === 1;

        const mobileClientUrl = this.urlConfig.get('MOBILE_CLIENT_URL');
        const normalizedUrl = TsUtils.replaceUrlDomain(mobileClientUrl, this.$window.location.hostname);

        this.link = normalizedUrl.replace('{room}', roomData._id);
        if (!this.accountData.protectedSettings || !this.accountData.protectedSettings.chromeDetection) {
            this.link += '&intent=no';
        }

        this.clientStartURL = TsUtils.replaceUrlDomain(CLIENT_START_URL, this.$window.location.hostname);

        if (this.accountData.settings && this.accountData.settings.cameraModeOnly) {
            this.link += '&camera-only=yes';
        }

        if (this.mediaServiceType) {
            this.link += '&media-service=' + this.mediaServiceType;

            if (this.accountData.protectedSettings.opentokVersion) {
                this.link += '&opentok-version=' + this.accountData.protectedSettings.opentokVersion;
            }
        }

        if (roomData.sentURL) {
            this.link = roomData.sentURL;
        }

        if (this.miniDashboard) {
            this.miniDashboardImageUploadFailed = this.$translate.instant('ERROR_DIALOG.VIEW.IMAGE_UPLOAD_FAILED');
        }

        const apiPrefix = tsUrlConfig.get('API_URL').match(/https?:\/\/([^.]+)\./);
        const hinter = apiPrefix ? API_TO_HINTER_MAP[apiPrefix[1].toLowerCase()] : null;

        this.sessionIdWithHint = roomData && roomData.guids ? roomData.guids[0] + (hinter ? hinter : '') : '';
        this.isGuestRole = currentUser.role === ROLES.GUEST_OBSERVER;

        if (this.isGuestRole && !this.observe) {
            this.stateHelper.safeGo('sessionEnded');

            return;
        }

        this.leftBarClosed = true;
        this.CONNECTION_STATUS = CONNECTION_STATUS;
        this.connected = CONNECTION_STATUS.WAITING;

        this.events = [];
        this.eventParams = {
            device: '',
            address: 'waiting...'
        };
        this.addressLocation = {
            translation: 'DASHBOARD.VIEW.WAITING_FOR_LOCATION',
            status: LOCATION_ADDRESS_STATUS.WAITING,
            icon: this.LOCATION_ICONS.UNAVAILABLE,
            popoverEnabled: false
        };
        this.miniPopoverLocation = this.$translate.instant('DASHBOARD.VIEW.LOCATION_UNAVAILABLE');

        this.mediaDevices = {};

        this.localeHelper.clear();

        this.tsDeviceClassificationService = tsDeviceClassificationService;
        this._initDeviceClassification(roomData, accountData, tsDeviceClassificationService);
        this._initUiSettings();
        this._initSimpleModals();

        if (this.isToggleEnabled_NewHeaderFooterLeftbar()) {
            const tempAccSettings = {
                generalSettings: {
                    emailImageSharing: this.emailImageSharing,
                    localImageSaving: this.localImageSaving,
                    logoUrl: this.accountData.settings.logoUrl,
                    enableMobileGeolocation: this.accountData.protectedSettings.enableMobileGeolocation
                },
                inviteMethodSettings: {
                    multiLanguage: this.accountData.settings.multiLanguage
                }
            };

            this.topBarController = new DashboardTopBarController(
                tempAccSettings,
                this.dashboardState,
                this.translate,
                this.onAbort,
                this.sendImage,
                this.saveResource,
                this.newTopbarHeaderCopyToClipboard,
                this.newTopbarHeaderEmail,
                this.newTopbarHeaderDownload,
                this.saveImageCloud,
                this.leaveOfflineRoom
            );

            if (!this.libraryTaggingMode) {
                this.footerController = new DashboardFooterController(get(this.roomData, 'guids')[0], this.translate);
                const leftBarOptions = {
                    desktopShareEnabled: get(this.accountData, 'protectedSettings.desktopSharing.enabled'),
                    isDeviceClassificationEnabled: get(this.accountData, 'protectedSettings.issuesAndSolutionsEnabled'),
                    enableObservation: this.enableObservation,
                    allowSendByEmail: this.allowSendByEmail,
                    allowInviteBySms: this.allowInviteBySms,
                    phoneCountryCodes: get(this.accountData, 'protectedSettings.selectedCountries'),
                    defaultCountryCode: get(this.accountData, 'settings.countryCode'),
                    keepCustomerMobile: get(this.accountData, 'protectedSettings.keepCustomerMobile'),
                    addCountryCodeToSms: get(this.accountData, 'protectedSettings.smsSettings.addCountryCodeToSms'),
                    selectedCountries: get(this.accountData, 'protectedSettings.selectedCountries'),
                    countryCode: this.accountData.settings.countryCode,
                    appLauncher: get(this.accountData, 'protectedSettings.appLauncher')
                };

                this.leftBarController = new DashboardLeftBarController(
                    this.translate,
                    this.environmentDetect,
                    leftBarOptions,
                    this.openHelp,
                    this.openDeviceClassification,
                    this.openSelfServiceWarmTransferSummary,
                    this.openModeSwitchConfirmationModal,
                    this.onSubmitObserver,
                    this.onSubmitAppLauncher
                );
            }
        }

        getRootStore().authService.on('newToken', () => {
            if (this._copilotElement) {
                this._copilotService?.reloadSession();
            }
        });

        this.deregisterIsUiReadyToLoadInteractionSummary = $rootScope.$on(
            MeetingStateEvents.isUiReadyToLoadInteractionSummary,
            async () => {
                this.openWidgetOnStartUp = !!get(this.roomData, 'pendingRoomId');
                this.loadWidgetDataOnStartUp =
                    !!get(this.roomData, 'pendingRoomId') &&
                    ((get(this.accountData, 'protectedSettings.interactionSummary.enabled') &&
                        get(this.roomData, 'sessionSource') === SESSION_SOURCE.AI) ||
                        (get(
                            this.accountData,
                            'protectedSettings.interactionSummary.enableVJInteractionSummarySearch'
                        ) &&
                            get(this.roomData, 'sessionSource') === SESSION_SOURCE.VJ));

                if (this.loadWidgetDataOnStartUp) {
                    this.isInteractionSummaryReady = true;
                }

                if (
                    get(this.accountData, 'protectedSettings.interactionSummary.enabled') ||
                    get(this.accountData, 'protectedSettings.interactionSummary.enableVJInteractionSummarySearch')
                ) {
                    this.interactionSummaryPreviews = await this.getInteractionSummaryPreviews(
                        get(this.accountData, 'protectedSettings.interactionSummary.enabled'),
                        get(this.accountData, 'protectedSettings.interactionSummary.enableVJInteractionSummarySearch')
                    );

                    if (this.interactionSummaryPreviews.length > 0) {
                        this.isInteractionSummaryReady = true;
                        $rootScope.$emit('previews', this.interactionSummaryPreviews);
                    }
                }
            }
        );

        this.$scope.$watch(
            () => get(this.chatApi, 'client.availableModes'),
            () => {
                const clientMode = get(this.chatApi, 'client.mode');
                const availableModes = get(this.chatApi, 'client.availableModes');

                if (clientMode && this.leftBarController && availableModes) {
                    let meetingModes = this.leftBarController.startWithModes;

                    meetingModes = filter(meetingModes, (mode) => includes(availableModes, mode.mode));
                    this.leftBarController.setAvailableMeetingModes(meetingModes);
                    this.leftBarController.setCurrentMeetingMode(clientMode);
                }
            }
        );

        if (this.embeddedDashboard) {
            this.audioService.on('audioEvent', (data) => {
                const reportedFieldType = data.type;

                if (this.miniDashboard && data.text) {
                    const text = data.text;
                    const timestamp = new Date().getTime();

                    this.lastEvent = {text: this.translate(text), timestamp};
                }

                this.db.Rooms.setReportedField(this.roomData._id, {
                    data: {
                        event: {
                            key: 'audioStatus',
                            value: {
                                type: reportedFieldType
                            },
                            type: 'push'
                        }
                    }
                });
            });
        }

        this.$scope.$watch(
            () => get(this.tsDeviceClassificationService, 'countMessagesArrived'),
            () => {
                this._updateSelfServiceData();
            }
        );

        this.$scope.$watchGroup(
            [
                () => this.displayImagesManagementButtons(),
                () => this.displaySendButton(),
                () => this.displaySaveButton(),
                () => this.displaySharingButton(),
                () => this.disabledSendButton(),
                () => this.disabledSaveButton(),
                () => this.disabledSharingButton(),
                () => this.displaySaveInHistoryButton()
            ],
            () => {
                if (this.topBarController) {
                    runInAction(() => {
                        this.topBarController.showImagesManagement = this.displayImagesManagementButtons();
                        this.topBarController.showSharingButton = this.displaySharingButton();
                        this.topBarController.showSaveButton = this.displaySaveButton();
                        this.topBarController.showSendButton = this.displaySendButton();
                        this.topBarController.showSaveInHistoryButton = this.displaySaveInHistoryButton();
                        this.topBarController.disabledSaveButton = this.disabledSaveButton();
                        this.topBarController.disabledSendButton = this.disabledSendButton();
                        this.topBarController.disabledSharingButton = this.disabledSharingButton();
                    });
                }
            }
        );

        this._initVideoTimeoutHackForSdk();
        this.roomCode = this.urlUtils.getParamValue('roomCode');

        if (this.offlineMode) {
            return;
        }

        if (!this.observe) {
            const apiPrefix = tsUrlConfig.get('API_URL').match(/https?:\/\/([^.]+)\./);
            const hinter = apiPrefix ? API_TO_HINTER_MAP[apiPrefix[1].toLowerCase()] : null;

            this.sessionIdWithHint = roomData.guids[0] + (hinter ? hinter : '');

            this.isFSSession = this.roomCode;
        }

        if (this.offlineRoom) {
            this.localeHelper.dashboardConnectedOffline();
        }

        this.enableNetworkBandwidthMeasurement =
            this.accountData.protectedSettings.enableNetworkBandwidthMeasurement && (!this.isIE || !this.isOpentok);

        this.cameraModeOnly = get(this.accountData, 'settings.cameraModeOnly');
        this.allowOneClickPhotoMode = get(this.accountData, 'settings.allowOneClickPhotoMode');
        this.idleMode = get(this.accountData, 'settings.idleMode');
        this.isAssurantDeviceSet =
            get(this.accountData, 'protectedSettings.smartDeviceSetId') === this.deviceSets.assurantDevices;
        this.recognizeLoader = false;
        this.spinnerOptions = {
            type: SpinnerTypeOptions.CIRCULAR,
            backgroundColor: SpinnerBackgroundColors.Transparent,
            size: SpinnerSizes.Large,
            color: SpinnerColors.LightBlue
        };

        if (!this.isIE || !this.isOpentok) {
            this.videoBandwidthSamplingInterval =
                this.accountData.protectedSettings.videoBandwidthSamplingInterval * 1000;
            this.minimalBandwidthKbps = this.accountData.protectedSettings.minimalVideoBandwidth;
            this.timeToWaitToStartBandwidthMeasurement =
                this.accountData.protectedSettings.timeToWaitToStartBandwidthMeasurement * 1000;
        }

        this.eventLog.userAgent({
            side: PlatformType.dashboard,
            userAgent: window.navigator.userAgent,
            clientVersion: CLIENT_VERSION
        });

        this._logIfPluginUnavailable();

        if (this.allowSessionSummary && !this.embeddedDashboard) {
            $window.onbeforeunload = (event) => {
                if (!this.isScreenModes || this.isDesktopSharingSession) {
                    event.returnValue = '';
                }
            };
        }

        this.deviceClassificationTags = [];
        this.showLoader = true;

        this.idleClient = {};
        this.idleClient.inviteMethod = this.browserUtilsService.getFromSessionStorage('InviteMethod');

        const notificationText =
            this.idleClient.inviteMethod === AddressTypesEnum.SMS
                ? 'MAIN.VIEW.MOBILE_NOT_CONNECTED_NOTIFICATION'
                : 'MAIN.VIEW.MOBILE_NOT_CONNECTED_NOTIFICATION_NO_SMS_CONNECTION';

        this.$translate(notificationText).then((m) => {
            this.idleClient.mobileNotConnectedNotification = m;
            this.idleClient.idleNotification = this.idleClient.mobileNotConnectedNotification;
        });

        this.$translate('MAIN.VIEW.WAITING_FOR_CLIENT').then((m) => (this.idleClient.waitForClientNotification = m));
        this.$translate('MAIN.VIEW.SEND_SMS').then((m) => (this.idleClient.sendSMSButtonText = m));
        this.$translate('MAIN.VIEW.RECOVERING_VIDEO').then((m) => (this.idleClient.connectingText = m));

        const enableMatomoAnalytics = get(this.accountData, 'protectedSettings.enableMatomoAnalytics');

        if (enableMatomoAnalytics) {
            $rootScope.loadScript(MATOMO_ANALYTICS.TRACKING);
            $rootScope.loadScript(MATOMO_ANALYTICS.SESSION_RECORDING);
        }

        this.tsQuickLauncher = tsQuickLauncher;
        this.enableQuickLaunch = get(this.accountData, 'protectedSettings.enableQuickLaunch');

        if (roomData.clientRedirectedToStore || roomData.clientVersionStatus === VersionStatus.INVALID) {
            this._displayRedirectToStoreGuidance(roomData.clientVersionStatus);
        }

        this.meetingSyncEnabled = false;
        this.mediaStatsLogger = new MediaStatsDataCollector(this.dashboardMediaService);
        this.modeSwitchModalController = new ModeSwitchModalController(
            this.translate,
            this.modeSwitchHandleModalResponse
        );
        this.dashboardGuidanceController = new DashboardGuidanceController(
            this.translate,
            this.dashboardState,
            this.displayEndMeetingDialog
        );
        this.tooltipPlacement = this.dir === 'rtl' ? 'left' : 'right';
        this.mobilePhonePattern = patternsConstant.mobilePhone;
        this.emailPattern = patternsConstant.email;
        this.displayObserverSendingForm = false;
        this.observationCurrentTab = this.allowInviteBySms ? AddressTypesEnum.SMS : AddressTypesEnum.EMAIL;
        this.inviteMethodTypes = AddressTypesEnum;
        this.phoneToInviteObserver = '';
        this.emailToInviteObserver = '';

        this.onRecordButton = this.onRecordButton.bind(this);

        this.deregisterMeetingStateConstructed = $rootScope.$on(MeetingStateEvents.MeetingStateConstructed, () => {
            if (this.meetingSyncEnabled) {
                this.syncMeeting();
            }
        });

        this.bandwidthMeasurementService = new BandwidthMeasurementService(
            this.enableNetworkBandwidthMeasurement,
            this.minimalBandwidthKbps,
            BANDWIDTH_SAMPLES_BUFFER_MAX_SIZE,
            get(this.accountData, 'protectedSettings.videoBandwidthSampleCountDuringSession') ||
                VIDEO_BANDWIDTH_SAMPLE_COUNT_DURING_SESSION
        );

        this.connectToMeeting();
    }

    get isRecordingEnabled() {
        return (
            (this.isMediaServer || this.isOpentok) &&
            !this.observe &&
            get(this.accountData, 'protectedSettings.recording.onDemand.enabled') &&
            get(this.chatApi, 'client.mode') === MeetingMode.video
        );
    }

    get recordingStatus() {
        return get(this.chatApi, 'dashboard.recordingStatus');
    }

    get isRecording() {
        return this.recordingStatus === RECORDING_STATUS.started;
    }

    get isRecordingFailed() {
        return this.recordingStatus === RECORDING_STATUS.failed;
    }

    setMessageAlert(label, color, timeout) {
        if (this.miniDashboard) {
            this.lastEvent = {text: label};

            return;
        }

        clearTimeout(this.alertMessageTimeout);

        this.messageAlert = {label, color, display: true};
        this.alertMessageTimeout = setTimeout(() => {
            this.messageAlert = null;
            this.alertMessageTimeout = null;
        }, timeout);
    }

    async getInteractionSummaryData(callParams, page, perPage) {
        const finalPerPage = get(this.accountData, 'protectedSettings.interactionSummary.perPage', perPage);
        const roomId = callParams?.sessionId || get(this.roomData, '_id', undefined);

        if (!roomId) {
            throw new Error('no session id was provided');
        }

        const interactionSummaryResults = await (callParams?.sessionId
            ? getRootStore().interactionSummaryService.getInteractionSummary(roomId, page, finalPerPage)
            : getRootStore().interactionSummaryService.getWarmTransferInteractionSummary(roomId, page, finalPerPage));

        if (get(interactionSummaryResults, 'sessionSource')) {
            interactionSummaryResults.sessionSource = this.setInteractionSummarySessionSource(
                interactionSummaryResults.sessionSource
            );
        }

        return interactionSummaryResults;
    }

    async getInteractionSummaryPreviews(enableAIInteractionSummary, enableVJInteractionSummary) {
        try {
            let res;
            let vjRes;
            let aiRes;

            if (enableVJInteractionSummary) {
                try {
                    vjRes = await getRootStore().interactionSummaryService.listInteractionSummaries(
                        this.accountData._id,
                        this.customerId,
                        undefined,
                        undefined,
                        this.accountData.protectedSettings.interactionSummary.historySearchPerPage,
                        undefined,
                        SESSION_SOURCE.VJ
                    );
                } catch (e) {
                    this.eventLog.getInteractionSummaryPreviewsFailed({e});
                }
            }
            if (enableAIInteractionSummary) {
                try {
                    aiRes = await getRootStore().interactionSummaryService.listInteractionSummaries(
                        this.accountData._id,
                        this.customerId,
                        undefined,
                        undefined,
                        this.accountData.protectedSettings.interactionSummary.historySearchPerPage,
                        undefined,
                        SESSION_SOURCE.AI
                    );
                } catch (e) {
                    this.eventLog.getInteractionSummaryPreviewsFailed({e});
                }
            }

            this.eventLog.getInteractionSummaryPreviewsSuccess();

            res = unionBy(vjRes?.previews || [], aiRes?.previews || [], 'sessionId');
            if (res && res.length) {
                res = res.map((preview) => {
                    preview.itemsText =
                        preview.sessionSource === SESSION_SOURCE.VJ
                            ? this.translate('REACT.INTERACTION.SUMMARY.VJ.LIST.IMAGES')
                            : this.translate('REACT.INTERACTION.SUMMARY.STEPS');
                    preview.totalResults =
                        preview.sessionSource === SESSION_SOURCE.VJ
                            ? preview.visualJourneyTotalImagesUploaded
                            : preview.totalResults;

                    preview.sessionSource = this.setInteractionSummarySessionSource(preview.sessionSource);

                    return preview;
                });

                return res;
            }

            return [];
        } catch (err) {
            this.eventLog.getInteractionSummaryPreviewsFailed({err});

            return [];
        }
    }

    getDateFormattedByAcc(date) {
        return getRootStore().getDateFormattedByAcc(date, this.accountData.protectedSettings.reportsDateFormat);
    }

    startRecording() {
        this.recordStatusChanged(RECORDING_STATUS.starting);

        setTimeout(() => {
            if (this.recordingStatus === RECORDING_STATUS.starting) {
                this.recordStatusChanged(RECORDING_STATUS.failed);
                this.setMessageAlert(
                    this.translate('REACT.DASHBOARD.VIEW.RECORD.FAILED_TO_START'),
                    RECORDING_ERROR_COLOR,
                    RECORDING_MESSAGE_ALERT_TIMEOUT
                );
            }
        }, RECORDING_FAILED_TIMEOUT);

        this.db.Rooms.startRecording(this.roomData._id, {
            data: {
                accountId: this.accountData._id,
                hasAudio: this.audioService.hasAudioStream()
            }
        }).catch((err) => {
            this.eventLog.failedToStartRecording({
                err
            });

            this.recordStatusChanged(RECORDING_STATUS.failed);
            this.setMessageAlert(
                this.translate('REACT.DASHBOARD.VIEW.RECORD.FAILED_TO_START'),
                RECORDING_ERROR_COLOR,
                RECORDING_MESSAGE_ALERT_TIMEOUT
            );
        });
    }

    recordingStatusMessage() {
        switch (this.recordingStatus) {
            case RECORDING_STATUS.ready:
            case RECORDING_STATUS.failed:
                return this.translate('REACT.FLOATING_TOOLBAR.RECORD.START');
            case RECORDING_STATUS.started:
                return this.translate('REACT.FLOATING_TOOLBAR.RECORD.STOP');
            case RECORDING_STATUS.stopping:
                return this.translate('REACT.FLOATING_TOOLBAR.RECORD.SAVING');
            case RECORDING_STATUS.starting:
                return this.translate('REACT.FLOATING_TOOLBAR.RECORD.STARTING');
            default:
                return null;
        }
    }

    stopRecording() {
        const accountId = this.accountData._id;

        this.recordStatusChanged(RECORDING_STATUS.stopping);

        setTimeout(() => {
            if (this.recordingStatus === RECORDING_STATUS.stopping) {
                this.recordStatusChanged(RECORDING_STATUS.failed);
                this.setMessageAlert(
                    this.translate('REACT.DASHBOARD.VIEW.RECORD.FAILED_TO_STOP'),
                    RECORDING_ERROR_COLOR,
                    RECORDING_MESSAGE_ALERT_TIMEOUT
                );
            }
        }, RECORDING_FAILED_TIMEOUT);

        this.db.Rooms.stopRecording(this.roomData._id, {
            data: {
                accountId: accountId
            }
        }).catch((err) => {
            this.eventLog.failedToStopRecording({
                err
            });

            this.recordStatusChanged(RECORDING_STATUS.failed);
            this.setRecordingFailedMessage(
                this.translate('REACT.DASHBOARD.VIEW.RECORD.FAILED_TO_STOP'),
                RECORDING_ERROR_COLOR,
                RECORDING_MESSAGE_ALERT_TIMEOUT
            );
        });
    }

    get recordButtonReady() {
        return (
            [RECORDING_STATUS.ready, RECORDING_STATUS.started].includes(this.recordingStatus) &&
            this.chatApi.areBothSidesConnected
        );
    }

    onRecordButton() {
        if (this.recordingStatus === RECORDING_STATUS.started) {
            return this.stopRecording();
        } else if (this.recordingStatus === RECORDING_STATUS.ready) {
            return this.startRecording();
        }
    }

    recordStatusChanged(status) {
        this.chatApi.setStatus('recordingStatus', status);
    }

    observerClick() {
        this.displayObserverSendingForm = !this.displayObserverSendingForm;
        this.$rootScope.safeApply();
    }

    observerTabChange(value) {
        this.observationCurrentTab = value;
        this.$rootScope.safeApply();
    }

    connectToMeeting() {
        trace.info('Connecting to meeting');

        const initialSyncHandler = () => {
            trace.info('Initial sync');

            this._initHandlers();

            Promise.resolve()
                .then(() => this.syncLanguage())
                .then(() => this.syncVideoSupport())
                .then(() => this.syncAudioSupport())
                .then(() => (this.meetingSyncEnabled = true))
                .then(() => this.syncMeeting())
                .catch((error) => {
                    this.meetingSyncEnabled = true;
                    trace.warn('Error during start meeting flow', error && error.toString());
                });
        };

        this.chatApi.once('sync', initialSyncHandler);
        this.$scope.$on('$destroy', () => this.chatApi.off('sync', initialSyncHandler));

        if (this.observe) {
            this.chatApi.connect(this.roomData._id, UserType.supervisor, null, this.currentUser._id);
        } else {
            this.chatApi.connect(this.roomData._id, UserType.dashboard, this.roomCode);
        }
    }

    _logIfPluginUnavailable() {
        const {videoPlayback} = this.dashboardMediaService.deviceSupportInfo;

        if (!videoPlayback && this.mediaServiceType === MEDIA_SERVICE.TURNSERVER) {
            this.eventLog.turnError({
                side: PlatformType.dashboard,
                description: 'plugin is not installed or disabled',
                error: 'PluginUnavailable',
                mediaServiceType: MEDIA_SERVICE.TURNSERVER
            });
        }
    }

    syncLanguage() {
        this.chatApi.setStatus('language', this.localeHelper.getLocale());

        return Promise.resolve();
    }

    syncVideoSupport() {
        if (this.cameraModeOnly && !this.allowScreenShare) {
            trace.info('Dashboard video playback support not needed, setting support false');

            this.chatApi.setStatus('videoSupport', false);
        } else {
            const {webRtcSupportInfo, videoPlayback} = this.dashboardMediaService.deviceSupportInfo;
            const isVideoSupported = videoPlayback && webRtcSupportInfo.isWebRTCSupported;

            trace.info('Dashboard video playback supported', isVideoSupported);

            this.chatApi.setStatus('videoSupport', isVideoSupported);
        }

        return Promise.resolve();
    }

    syncAudioSupport() {
        return Promise.resolve().then(() => {
            this.audioService.enableVoipDuringSession();
        });
    }

    get isScreenModes() {
        return utils.isModeSupportNativeVideoSharing(get(this.chatApi, 'client.mode'));
    }

    get isSupportAudio() {
        return this.chatApi.dashboard.audioSupport && utils.isAudioAvailableInMode(get(this.chatApi, 'client.mode'));
    }

    syncMeeting() {
        if (!this.meetingSyncEnabled) {
            trace.info('Sync arrived from BE, but not ready to sync yet');

            return Promise.resolve();
        }

        trace.info('Start syncMeeting flow');

        return Promise.resolve()
            .then(() => this.syncRoom())
            .then(() => this.syncMediaSession())
            .then(() => this.syncRoute())
            .then(() => this.syncAgentGuidance())
            .then(() => this.syncDeviceDetails())
            .then(() => this.syncCountries())
            .then(() => this.syncLeftBar())
            .then(() => this.syncTopBar())
            .then(() => this.syncFeatures())
            .then(() => {
                this.$rootScope.$emit(MeetingStateEvents.MeetingSyncRequest);
            })
            .catch((error) => {
                trace.info('Sync meeting flow error', error);
            });
    }

    syncRoom() {
        // Initial connectivity, in case user already connected during invite
        if (this.connected === CONNECTION_STATUS.WAITING && this.chatApi.client.connected) {
            this.connected = CONNECTION_STATUS.CONNECTED;
        }

        this.clientDisconnected = !this.chatApi.client.connected && this.chatApi.client.connected !== undefined;
        const isOffline =
            this.clientDisconnected ||
            (!this.isDesktopSharingSession && this.connected === this.CONNECTION_STATUS.IDLE);

        this.cameraApproval = this.isHandshakeSuccessForCurrentMode;

        runInAction(() => {
            this.dashboardState.connectionState = isOffline ? ConnectionStatesEnum.OFFLINE : ConnectionStatesEnum.HIGH;
        });

        if (this.chatApi.dashboard.roomStarted || this.clientDisconnected) {
            return this.$q((resolve) => resolve(this.roomData));
        }

        return this.roomData.start().then((roomData) => {
            this.roomData = roomData;

            this.chatApi.setStatus('roomStarted', true);

            return roomData;
        });
    }

    // eslint-disable-next-line complexity
    syncMediaSession() {
        trace.info('syncMediaSession');
        const clientMode = get(this.chatApi, 'client.mode');

        if (!clientMode) {
            //No need to connect to media session while mode is unknown
            return Promise.resolve();
        }

        const mediaSessionModes = [
            MeetingMode.video,
            MeetingMode.screen,
            MeetingMode.appSharing,
            MeetingMode.videoApplication
        ];
        const isMediaSessionMode = includes(mediaSessionModes, this.chatApi.client.mode);
        const sessionIsNeeded = this.dashboardMediaService.isVoipEnabled || isMediaSessionMode;

        this._stopRecordingWhenNotInVideoMode();

        const isHandshakeSuccess = this.isHandshakeSuccessForCurrentMode;

        const traceObj = {
            sessionIsNeeded,
            areBothSidesConnected: this.chatApi.areBothSidesConnected,
            isHandshakeSuccess,
            isSessionActive: this.dashboardMediaService.isSessionActive
        };

        if (this.sessionInitializationInProgress) {
            return Promise.resolve();
        }

        if (sessionIsNeeded && !this.dashboardMediaService.isSessionActive && this.chatApi.areBothSidesConnected) {
            trace.info('dashboard sync media', traceObj);

            if (!isHandshakeSuccess) {
                trace.info('Session is needed, but handshake not complete yet.', traceObj);

                return Promise.resolve();
            }

            //TODO - Alex: Move the connection logic to dashboardMediaService itself
            trace.info('Session is needed, will try to connect');
            const mediaSessionParams = {
                sessionId: this.roomData._id,
                clientType: SessionClientType.GUEST,
                clientRole: this.observe ? SessionClientRole.OBSERVER : SessionClientRole.AGENT
            };

            let paramsReadyPromise = Promise.resolve();

            this.sessionInitializationInProgress = true;

            const syncOTCredentials = () => {
                let token = this.roomData.opentok.tokenAgent;

                if (this.observe) {
                    const tokenObserve = filter(
                        this.roomData.opentok.tokenObservers,
                        (observer) => observer.userId === this.currentUser._id.toString()
                    );

                    token = tokenObserve && tokenObserve[0] && tokenObserve[0].token;
                }

                this.roomData.opentok.token = token;

                mediaSessionParams.credentials = assign({}, this.roomData.opentok);
            };

            switch (this.mediaServiceType) {
                case MEDIA_SERVICE.MEDIASERVER:
                    this.dashboardMediaService.connectToMediaServer();
                    paramsReadyPromise = this.roomData
                        .initMediaServer(
                            this.observe ? UserType.supervisor : UserType.dashboard,
                            get(this.chatApi, 'dashboard.audioSupport') && get(this.chatApi, 'client.audioSupport')
                        )
                        .then((roomData) => {
                            this.roomData = roomData;
                            const sessionId = this.roomData.mediaServer.sessionId;
                            let token = this.roomData.mediaServer.tokenAgent;

                            if (this.observe) {
                                const tokenObserve = filter(
                                    this.roomData.mediaServer.tokenObservers,
                                    (observer) => observer.userId === this.currentUser._id.toString()
                                );

                                token = tokenObserve && tokenObserve[0] && tokenObserve[0].token;
                            }

                            mediaSessionParams.credentials = assign({}, {token, sessionId});

                            trace.info('setStatus --> mediaServer credentials');

                            this.chatApi.setStatus('mediaServer', {
                                session: {
                                    sessionId: mediaSessionParams.credentials.sessionId,
                                    token: this.roomData.mediaServer.tokenClient
                                }
                            });
                        });
                    break;
                case MEDIA_SERVICE.OPENTOK:
                    if (!this.roomData.opentok.init || this.observe) {
                        trace.info(
                            'session opentok created',
                            this.roomData.opentok,
                            this.observe,
                            this.roomData.opentok
                        );

                        paramsReadyPromise = this.roomData
                            .initOpentok(this.observe ? UserType.supervisor : UserType.dashboard)
                            .then((roomData) => {
                                this.roomData = roomData;
                                syncOTCredentials();
                            });
                    } else {
                        syncOTCredentials();
                    }
                    mediaSessionParams.ipWhitelist = get(
                        this.chatApi,
                        'accountSettings.useOnlyOpenTokAllowedIPS',
                        false
                    );
                    break;
                default:
                    mediaSessionParams.credentials = this.chatApi.accountSettings.turnServerConfig;
            }

            return paramsReadyPromise
                .then(() => {
                    if (this.isMediaServer) {
                        this.dashboardMediaService.stopWaitForMediaServer();
                    }

                    if (
                        !mediaSessionParams.credentials ||
                        Object.getOwnPropertyNames(mediaSessionParams.credentials).length < 2
                    ) {
                        trace.info(
                            'Credentials for media session not exists. Skipping connect to session',
                            mediaSessionParams.credentials
                        );

                        return Promise.resolve();
                    }

                    trace.info('try to connect to media session');

                    return this.dashboardMediaService
                        .connectToSession(mediaSessionParams, isMediaSessionMode)
                        .then(() => {
                            trace.info('connected to media session');

                            this._recordingEventsListener();

                            const isLoggingEnabled = includes(mediaSessionModes, clientMode);

                            this.mediaStatsLogger.enableVideoLogging(isLoggingEnabled);
                            this.audioService.establishAudioStream();

                            if (this.isOpentok) {
                                trace.info('setStatus --> opentok credentials');

                                this.chatApi.setStatus('opentok', {
                                    session: {
                                        sessionId: mediaSessionParams.credentials.sessionId,
                                        token: mediaSessionParams.credentials.tokenClient,
                                        apiKey: mediaSessionParams.credentials.apiKey
                                    }
                                });
                            }
                        });
                })
                .catch((error) => {
                    trace.warn('error during connection to media session', error);
                })
                .finally(() => (this.sessionInitializationInProgress = false));
        } else if (
            !isMediaSessionMode ||
            (!this.isOpentok && !this.chatApi.areBothSidesConnected) ||
            (!this.isOpentok && !isHandshakeSuccess)
        ) {
            if (this.dashboardMediaService.isSessionActive) {
                trace.info('Disconnecting from media session:', traceObj);

                return this.dashboardMediaService.disconnectFromSession();
            }

            this.mediaStatsLogger.enableVideoLogging(false);
        }

        trace.info('No change in media session state required');

        return Promise.resolve();
    }

    _stopRecordingWhenNotInVideoMode() {
        if (
            this.isRecordingEnabled &&
            get(this.chatApi, 'client.mode') !== MeetingMode.video &&
            (get(this.accountData, 'protectedSettings.recording.automatic.enabled') ||
                this.recordingStatus === RECORDING_STATUS.started)
        ) {
            this.db.Rooms.stopRecording(this.roomData._id, {
                data: {
                    accountId: this.accountData._id
                }
            });

            this.recordStatusChanged(RECORDING_STATUS.ready);
        }
    }

    _recordingEventsListener() {
        if (this.isRecordingEnabled) {
            this.dashboardMediaService.onReconnectingMediaServer(() => {
                trace.info('Reconnecting to media server');

                this.syncMediaSession();
            });

            this.dashboardMediaService.onRecordStatusChange((eventArgs) => {
                trace.info(`Getting status: ${eventArgs.status} of the recordingId: ${eventArgs.recordingId}`);

                this.recordStatusChanged(eventArgs.status);
            });
        }
    }

    syncRoute() {
        if (!this.chatApi.dashboard.roomStarted && !this.chatApi.client.connected) {
            return;
        }

        if (
            (!this.wizardState.showWizard && !this.isMobile) ||
            (this.chatApi.dashboard.roomStarted && this.chatApi.client.connected) ||
            this.observe
        ) {
            this.redirectToMain();
        }
    }

    syncAgentGuidance() {
        const {
            inCameraApprovalDialog,
            isOnScreenShareCapturingPermission,
            mode,
            clientOs,
            screenShareCapturingApprovedIOS,
            isOnscreenShareTurnOffGuidanceIOS
        } = this.chatApi.client;
        const {screenShareFailed} = this.chatApi.dashboard;
        const isReviewingTOS = this.chatApi.isReviewingTOS;
        const switchingToAppShare = mode === MeetingMode.appSharing;
        const switchingToScreenShare = mode === MeetingMode.screen && !this.isDesktopSharingSession;
        const isIOS = clientOs && clientOs.toLowerCase().indexOf('ios') !== -1;

        this.screenShareIOS = switchingToScreenShare && isIOS;

        switch (true) {
            case isOnscreenShareTurnOffGuidanceIOS && isIOS && this.isLiveModeOptionAvailable() && !screenShareFailed:
                this.dashboardGuidanceController.setGoToApplicationGuidance();
                break;
            case isReviewingTOS:
                this._setTOSGuidance(switchingToAppShare, switchingToScreenShare);
                break;
            case inCameraApprovalDialog:
                trace.info('dashboardGuidance - inCameraApprovalDialog');
                this.dashboardGuidanceController.setCameraApprovalGuidance(this.isDesktopSharingSession);
                break;
            case (isOnScreenShareCapturingPermission || (this.screenShareIOS && !screenShareCapturingApprovedIOS)) &&
                !screenShareFailed:
                this.dashboardGuidanceController.setScreenShareCapturingGuidance();
                break;
            default:
                if (!this.rejectedTermsAndConditions) {
                    trace.info('dashboardGuidance - hide guidance');

                    if (this.dashboardGuidanceController.isCameraApprovalShown) {
                        this.eventLog.cameraApprovalGuidanceClosed({trigger: 'SYNC'});
                    }

                    this.dashboardGuidanceController.hide();
                }
                break;
        }

        return Promise.resolve();
    }

    syncDeviceDetails() {
        if (
            get(this.chatApi, 'client.deviceInfo') &&
            get(this.chatApi, 'client.clientOsObject') &&
            get(this.chatApi, 'client.clientType')
        ) {
            const {clientOs, clientOsObject, deviceInfo, clientType, clientBrowser, clientDevice, usingApplication} =
                this.chatApi.client;

            this._clientDeviceDetails({
                clientOs,
                clientOsObject,
                deviceInfo,
                clientType,
                clientBrowser,
                clientDevice,
                usingApplication
            });

            return;
        }

        return this.db.Rooms.getClientDeviceDetails(this.roomData._id).then((room) => {
            if (!get(room, 'data.deviceInfo') || !get(room, 'data.clientOsObject') || !get(room, 'data.clientType')) {
                return;
            }

            const {clientOs, clientOsObject, deviceInfo, clientType, clientBrowser, clientDevice, usingApplication} =
                room.data;

            this._clientDeviceDetails({
                clientOs,
                clientOsObject,
                deviceInfo,
                clientType,
                clientBrowser,
                clientDevice,
                usingApplication
            });
        });
    }

    isLiveModeOptionAvailable() {
        const liveModes = [MeetingMode.video, MeetingMode.oneClick, MeetingMode.images];
        const availableModes = get(this.chatApi, 'client.availableModes');
        let isLiveAvailable = false;

        forEach(liveModes, (liveMode) => {
            isLiveAvailable = !!find(availableModes, (mode) => mode === liveMode);
        });

        return isLiveAvailable;
    }

    syncCountries() {
        if (!this.enableObservation || !isEmpty(this.countries)) {
            return Promise.resolve();
        }

        this.countries = countryCodes;
        this.countriesInfoList = this.countries.filter(
            (country) =>
                this.accountData.protectedSettings.selectedCountries.indexOf(country.code) > -1 ||
                `+${this.accountData.settings.countryCode}` === country.dial
        );
    }

    syncLeftBar() {
        const clientMode = get(this.chatApi, 'client.mode');
        const availableModes = get(this.chatApi, 'client.availableModes');

        if (!this.leftBarController || !clientMode || !availableModes) {
            return Promise.resolve();
        }

        const options = {
            accountType: get(this.accountData, 'accountType'),
            clientType: get(this.chatApi, 'client.clientType'),
            isClientVisible: get(this.chatApi, 'client.visible') && get(this.chatApi, 'client.connected'),
            usingApplication: this.browserUtilsService.getFromSessionStorage('usingApplication'),
            isObserve: this.observe,
            patterns: {
                email: this.emailPattern,
                phone: this.mobilePhonePattern
            },
            countryList: this.countries,
            countriesInfoList: this.countriesInfoList
        };

        let meetingModes = get(this.accountData, 'protectedSettings.startWithModes.modesOptions');

        meetingModes = filter(meetingModes, (mode) => includes(availableModes, mode.mode));

        this.leftBarController.setOptions(options);

        this.leftBarController.setAvailableMeetingModes(meetingModes);
        this.leftBarController.setCurrentMeetingMode(clientMode);

        return Promise.resolve();
    }

    syncTopBar() {
        const clientMode = get(this.chatApi, 'client.mode');

        if (!this.topBarController || !clientMode) {
            return Promise.resolve();
        }

        const usingApplication =
            this.browserUtilsService.getFromSessionStorage('usingApplication') ||
            utils.isModeSupportNativeVideoSharing(clientMode);
        const nativeAppId = get(this.accountData, 'protectedSettings.liveSdk.nativeAppId');
        const options = {
            nativeAppId,
            usingApplication,
            isDesktopSharing: this.isDesktopSharingSession
        };

        this.topBarController.setOptions(options);

        runInAction(() => {
            this.dashboardState.sessionInfo.meetingMode = clientMode;
        });

        return Promise.resolve();
    }

    syncFeatures() {
        return Promise.all([this._handleAgentPointerAvailability(), this._handleDesktopSharingAgentControlStatus()]);
    }

    _shouldRouteToSessionEndedState() {
        const shouldRedirect = this.miniDashboard && (this.isMobile || this.isTablet);

        if (shouldRedirect) {
            this.stateHelper.safeGo('sessionEnded');
        }

        return shouldRedirect;
    }

    _initVideoTimeoutHackForSdk() {
        if (!this.enableVideoTimeoutHackForSdk) {
            return;
        }

        const videoTimeoutHackForSdkRoomId =
            this.browserUtilsService.getFromSessionStorage('videoTimeoutHackForSdkRoomId');

        if (videoTimeoutHackForSdkRoomId !== this.roomData._id) {
            this.browserUtilsService.saveToSessionStorage('videoTimeoutHackForSdk', false);
            this.browserUtilsService.saveToSessionStorage('videoTimeoutHackForSdkRoomId', this.roomData._id);
        }
    }

    _initHandlers() {
        if (this.observe) {
            this._logObservationContinues();
        }

        const currentObserver = get(this.chatApi, 'dashboard.observer');

        if (!this.observe && !isEmpty(currentObserver)) {
            this._updateObserverDetails(currentObserver);
        }

        this.jsApiService.sessionStarted();

        this.dashboardMediaService.onUserVideoStateChange((eventArgs) => {
            trace.info('onUserVideoStateChange - video stream has changed', eventArgs);

            this.videoStateChanged(eventArgs.isReady);
        });

        this.dashboardMediaService.onUserVideoStreamTimeout(() => {
            if (this.enableVideoTimeoutHackForSdk) {
                const clientMode = get(this.chatApi, 'client.mode');

                if (
                    utils.isModeSupportNativeVideoSharing(clientMode) &&
                    !this.isDesktopSharingSession &&
                    !this.browserUtilsService.getFromSessionStorage('videoTimeoutHackForSdk')
                ) {
                    this.browserUtilsService.saveToSessionStorage('videoTimeoutHackForSdk', true);

                    trace.info(`Failed to receive video - reload page, Mode: ${clientMode}`);

                    this.eventLog.videoStreamFailed({
                        mode: clientMode,
                        videoTimeoutHackForSdk: true
                    });

                    return window.location.reload();
                }
            }

            trace.info('Failed to receive video during defined timeout');

            this.chatApi.requestSwitchMode(FailureTriggerAction.dashboardNetworkIssue);
            this._initTrafficLogger(false);
            this._initVideoDurationLogger(false);
            this.eventLog.videoStreamFailed({
                reason: 'timeout'
            });
        });

        this.busy.on('busy', () => (this.showSpinner = true));
        this.busy.on('free', () => (this.showSpinner = false));

        this.chatApi.on('sync', () => this.syncMeeting());

        this.bandwidthMeasurementService.on('bandwidthMeasurementSamples', (measurements) => {
            this.db.BandwidthMeasurement.add({
                data: {
                    roomId: this.roomData._id,
                    accountId: this.accountData._id,
                    measurements: measurements.data
                }
            });
        });

        // eslint-disable-next-line complexity
        this.chatApi.on('log', (log, sender, isNew) => {
            switch (log.name) {
                case 'CUSTOMER_OPENED_THE_CAMERA':
                    this.eventLog.cameraOpened();
                    break;
                case 'CUSTOMER_ENDED_THE_MEETING':
                    this.showCustomerEndedTheMeetingModal();
                    this.customerEndedTheMeeting = true;
                    this.jsApiService.sessionEnded({
                        type: MessageType.sessionEnded,
                        sessionId: this.roomData._id,
                        techseeSessionId: get(this.roomData, 'guids')[0],
                        customerNumber: this.customerNumber,
                        customerId: this.customerId
                    });
                    this.autoDataCollectionServices.end();
                    break;
                case 'CLIENT_LOCATION_DENIED':
                    this._updateAddressTranslation(LOCATION_ADDRESS_STATUS.FAILURE);
                    this._topbarAddressFailure(true);
                    this.eventLog.clientLocationDenied();
                    break;
                case 'CLIENT_LOCATION_ERROR':
                    this._updateAddressTranslation(LOCATION_ADDRESS_STATUS.FAILURE);
                    this._topbarAddressFailure();
                    this.eventLog.clientLocationError();
                    break;
                case 'CLIENT_LOCATION':
                    if (this.chatApi.client.location) {
                        this.eventLog.clientLocation({coords: this.chatApi.client.location});
                        this._getAddress(this.chatApi.client.location).then(() =>
                            this.localeHelper.handleMessage(log, sender, isNew)
                        );
                    }
                    break;
                case 'DASHBOARD_NETWORK_ISSUE':
                    if (isNew) {
                        this.chatApi.requestSwitchMode(FailureTriggerAction.dashboardNetworkIssue);
                    }
                    break;
                case 'UNABLE_TO_PUBLISH':
                    if (isNew) {
                        if (this.chatApi.client.photoSupport) {
                            this.cameraApprovalIndicationNotRequired = true;

                            if (this.dashboardGuidanceController.isCameraApprovalShown) {
                                this.dashboardGuidanceController.hide();
                                this.eventLog.cameraApprovalGuidanceClosed({trigger: 'UNABLE_TO_PUBLISH'});
                            }
                        }
                    }
                    break;
                case 'MEDIA_PERMISSION_ALLOW':
                    this.cameraApprovalIndicationNotRequired = true;

                    this.db.Rooms.mediaPermissionAllow(this.roomData._id, {data: {timestamp: new Date()}});

                    if (this.dashboardGuidanceController.isCameraApprovalShown) {
                        this.dashboardGuidanceController.hide();
                        this.eventLog.cameraApprovalGuidanceClosed({trigger: 'MEDIA_PERMISSION_ALLOW'});
                    }
                    break;
                case 'TOS_REJECTED':
                    if (this.chatApi.accountSettings.enableNewInvite) {
                        this.rejectedTermsAndConditions = this.chatApi.client.tosRejected;
                    } else {
                        this.rejectedTermsAndConditions = true;
                        this.dashboardGuidanceController.setTosRejected(this.endMeeting);
                    }

                    this._shouldRouteToSessionEndedState();

                    break;
                case 'TOS_ACCEPTED':
                    this.tosAccepted = true;
                    break;
                case 'VIDEO_PAUSED_BY_CUSTOMER':
                case 'VIDEO_PAUSED_BY_EXPERT':
                case 'SCREEN_SHARE_PAUSED_BY_EXPERT':
                case 'SCREEN_SHARE_PAUSED_BY_CUSTOMER':
                case 'APP_SHARE_PAUSED_BY_EXPERT':
                case 'APP_SHARE_PAUSED_BY_CUSTOMER':
                case 'VIDEO_RESUMED':
                case 'SCREEN_SHARE_RESUMED':
                case 'APP_SHARE_RESUMED':
                    break;
                case 'CLIENT_DISCONNECTED':
                case 'CLIENT_HIDDEN':
                    if (this.offlineRoom) {
                        log.name = 'CLIENT_LEFT_OFFLINE';
                    }
                    break;
                case 'IMAGE_UPLOAD_UNSUPPORTED':
                    this.photostreamBlockedAlert('LOGS.IMAGE_UPLOAD_UNSUPPORTED');
                    break;
                default:
                    break;
            }

            if (this._shouldHandleLogMessage(log.name)) {
                this.localeHelper.handleMessage(log, sender, isNew);
            }
        });

        this.chatApi.on('endMeetingAction', () => this.endMeeting(true, this.LogEvents.closedByTypes.simpleApi));
        this.chatApi.on('redirectedToStore', () => this._displayRedirectToStoreGuidance());
        this.chatApi.on('clientVersionStatus', (status) => this._displayRedirectToStoreGuidance(status));
        this.chatApi.on('clientMediaServiceType', (param, newMediaServiceType) => {
            if (newMediaServiceType && this.mediaServiceType && newMediaServiceType !== this.mediaServiceType) {
                // This is currently the best way to handle media service type change during the session
                this.$window.location.reload(true);
            }
        });
        this.chatApi.on('clientDeviceDetails', (message) => this._clientDeviceDetails(message));
        this.chatApi.on('socketVersion', (message) => onSocketVersionHandler(message));
        this.chatApi.on('clientAudioSupport', () => {
            if (get(this.chatApi, 'client.audioSupport') !== null) {
                this.syncAudioSupport();
            }
        });
        this.chatApi.on('clientConnected', () => {
            const clientConnected = get(this.chatApi, 'client.connected');

            if (clientConnected) {
                this.clientConnected = true;
                this.errorDialogModal.hide();
                this.roomData.connect();

                this.localeHelper.mobileClientConnected();

                if (this.chatApi.client.visible) {
                    this._allowIdleNotificationSms();
                } else if (this.connected !== this.CONNECTION_STATUS.IDLE) {
                    this.localeHelper.mobileClientHidden();
                    this.connected = this.CONNECTION_STATUS.IDLE;
                }

                if (this.redirectToStore) {
                    this.redirectToMain();
                }

                return;
            }

            this.localeHelper.mobileClientDisconnected();
            this.busy.free();
        });
        this.chatApi.on('clientLocation', (param, coords) => {
            this._getAddress(coords);
        });
        this.chatApi.on('roomRejected', () => {
            this.chatApi.sendLog(new Error('Room rejected'));
        });
        this.chatApi.on('clientVisible', (param, state) => {
            if (state) {
                this._allowIdleNotificationSms();
                this.localeHelper.mobileClientVisible();
            } else {
                this.localeHelper.mobileClientHidden();
            }

            this.connected = state ? this.CONNECTION_STATUS.CONNECTED : this.CONNECTION_STATUS.IDLE;
        });
        this.chatApi.on('clientMode', (param, clientMode) => {
            if ([MeetingMode.images, MeetingMode.oneClick].includes(clientMode)) {
                this.db.Rooms.lastPhotoStreamReason(this.roomData._id).then((photoStreamReason) => {
                    const reason = photoStreamReason && photoStreamReason.data;

                    if (
                        this.fallbackModeNotification &&
                        reason &&
                        !includes(
                            [PhotoStreamReasonKeys.BUTTON_CLICK, PhotoStreamReasonKeys.ACCOUNT_VIDEO_DISABLED],
                            reason
                        ) &&
                        updateFallbackModesNotification(clientMode, this.roomData._id, this.browserUtilsService)
                    ) {
                        this.fallbackModeController.show(clientMode, photoStreamReason.data);
                    }
                });
            }
        });
        this.chatApi.on('connectionStatusChanged', (status) => {
            if (status) {
                this.errorDialogModal.hide();

                return;
            }

            this.dialogFactory.hideAll();

            // Don't log connectivity issue after meeting ended
            if (get(this.chatApi, 'dashboard.meeting') || !(this.endedTheMeeting || this.customerEndedTheMeeting)) {
                this.chatApi.sendLog(this.STATUS_MESSAGES.CONNECTIVITY_DIFFICULTIES);
                this.eventLog.connectivityDifficulties();
            }
        });
        this.chatApi.on('mobileNetworkType', (networkInfo) => {
            const mobileNetworkInfo = NetworkInfo.fromDataObject(networkInfo);

            runInAction(() => {
                this.dashboardState.clientNetworkInfo.downlinkMax = mobileNetworkInfo.downlinkMax;
                this.dashboardState.clientNetworkInfo.connectionType = mobileNetworkInfo.connectionType;
            });

            const type = mobileNetworkInfo.connectionType;

            switch (type.toLowerCase()) {
                case 'cellular':
                    this.localeHelper.mobileNetworkCell(type);
                    break;
                case 'unsupported': //TODO - Alex: Check if something needed to be done in old ui.
                case 'wifi':
                    this.localeHelper.mobileNetworkWifi(type);
                // eslint-disable-next-line no-fallthrough
                default:
                    break;
            }
        });
        this.chatApi.on('roomUpdated', (customerId) => {
            this.customerId = customerId;
            this.roomData.customerId = customerId;
        });
        this.chatApi.on('ICECredentialsChanged', ({credentials}) => {
            if (this.mediaServiceType === MEDIA_SERVICE.MEDIASERVER) {
                this.chatApi.accountSettings.mediaServerConfig = credentials;
            } else if (this.mediaServiceType === MEDIA_SERVICE.TURNSERVER) {
                this.chatApi.accountSettings.turnServerConfig = credentials;
            }
            if (this.dashboardMediaService.isSessionActive) {
                this.dashboardMediaService.updateSessionCredentials(credentials).catch((error) => {
                    trace.warn('Update credentials error', error);
                });
            }
        });

        this.chatApi.on('observer', (data) => {
            this._updateObserverDetails(data);
        });

        if (!this.observe) {
            this.chatApi.on('timeout', () => {
                this.displayTimeoutDialog();
            });
            this.chatApi.on('forceTimeout', () =>
                this.endMeeting(false, this.LogEvents.closedByTypes.dashboard, ReasonForClosing.forceTimeout)
            );
        } else {
            this.chatApi.on('disconnectObserver', () => {
                this.dashboardMediaService.disconnectFromSession().catch(() => undefined);
                this.chatApi.disconnect();
                this.connected = CONNECTION_STATUS.DISCONNECTED;
                this.goToInvite();
            });
        }

        this.localeHelper.on('update', (eventLogs) => (this.events = eventLogs));
        this.localeHelper.on('newEvent', (eventLog) => {
            this.handleLog(eventLog);
        });
        this.localeHelper.on('clientConnectionStatus', (status) => (this.connected = status));

        const mobilePauseDisposer = this.$rootScope.$on(MeetingStateEvents.MobilePause, (event, isPaused) => {
            this.isPaused = isPaused;

            if (!this.isPaused && this.enableNetworkBandwidthMeasurement) {
                this.resetVideoBandwidthTest = true;
            }
        });

        this.$scope.$on('$destroy', () => {
            clearTimeout(this.observableTimeout);
            clearTimeout(this.observeTimeout);
            clearTimeout(this.alertMessageTimeout);
            this.jsApiService.reset();
            mobilePauseDisposer();
            this.deregisterMeetingStateConstructed();
            this.deregisterIsUiReadyToLoadInteractionSummary();
            this._resetUnloadConfirmation();

            if (this.isRecordingEnabled) {
                if (this.isRecording) {
                    this.stopRecording();
                }
            }
        });
    }

    _shouldHandleLogMessage(logName) {
        switch (logName) {
            case 'CLIENT_LOCATION':
                return false;
            case 'CUSTOMER_IN_PHOTOGALLERY_PAGE':
                return !this.observe;
            default:
                return true;
        }
    }

    _updateObserverDetails(data) {
        if (!this.enableObservation) {
            return;
        }

        if (data.connected) {
            this.audioService.observerConnected();
        }

        this.observerDetails = {
            connected: data.connected,
            firstName: data.firstName,
            lastName: data.lastName
        };

        if (!this.invisibleModeObservation) {
            this._manageObserverAlerts(data);
        }

        this.observerInfo = this.translate('REACT.LEFTBAR.POPOVER.SESSION_OBSERVER_CONNECTED', {
            user: `${data.firstName} ${data.lastName}`
        });

        if (this.miniDashboard) {
            return;
        }

        runInAction(() => {
            this.leftBarController.setIsObserverConnected(data.connected);
            this.leftBarController.observerDetails = {
                connected: data.connected,
                firstName: data.firstName,
                lastName: data.lastName
            };
        });
    }

    _manageObserverAlerts(data, failedMessage) {
        const observerAlertMessage = data.connected
            ? 'DASHBOARD.VIEW.OBSERVER_JOIN_THE_SESSION'
            : 'DASHBOARD.VIEW.OBSERVER_LEFT_THE_SESSION';
        const label = failedMessage
            ? this.translate(failedMessage)
            : this.translate(observerAlertMessage, {user: `${data.firstName} ${data.lastName}`});

        this.setMessageAlert(label, OBSERVER_ALERT_COLOR, OBSERVER_JOIN_ALERT_TIMEOUT);
    }

    _updateSelfServiceData() {
        if (this.selfServiceController) {
            const deviceName = get(this.tsDeviceClassificationService, 'deviceName');
            const ledsMapping = get(this.tsDeviceClassificationService, 'analysisData.ledStatus');
            const portsMapping = get(this.tsDeviceClassificationService, 'analysisData.cables');
            const steps = get(this.tsDeviceClassificationService, 'analysisData.successfulSteps');

            if (deviceName) {
                this.selfServiceController.setDeviceType(deviceName);
            }

            if (steps) {
                this.selfServiceController.setSteps(steps);
            }

            if (ledsMapping) {
                this.selfServiceController.setLedsMapping(ledsMapping);
            }

            if (portsMapping) {
                this.selfServiceController.setPortsMapping(portsMapping);
            }

            this.leftBarController.setSelfServiceSummaryButton();
        }
    }

    showCustomerEndedTheMeetingModal() {
        if (this.dashboardGuidanceController.isCameraApprovalShown) {
            this.eventLog.cameraApprovalGuidanceClosed({trigger: 'Customer ended the session'});
        }

        this.dashboardGuidanceController.hide();
        this.customerEndedTheMeetingModalCtrl.show();
    }

    onSubmitObserver(value, methodType) {
        const email = value ? value : this.emailToInviteObserver;
        const phone = value ? value : this.countrySelector.model + this.phoneToInviteObserver;
        const selectedInviteType = methodType ? methodType : this.observationCurrentTab;

        if (email && selectedInviteType === AddressTypesEnum.EMAIL) {
            this.sendObserverEmail(email).catch(() => {
                this._manageObserverAlerts({}, 'REACT.INVITE.VIEW.SESSION_OBSERVATION.FAILED_INVITATION');
            });
        } else if (phone && selectedInviteType === AddressTypesEnum.SMS) {
            this.sendObserverSMS(phone).catch(() => {
                this._manageObserverAlerts({}, 'REACT.INVITE.VIEW.SESSION_OBSERVATION.FAILED_INVITATION');
            });
        }

        this.displayObserverSendingForm ? this.observerClick() : this.leftBarController.closeObserverInvite();
    }

    onSubmitAppLauncher(appLauncherType) {
        switch (appLauncherType) {
            case AppLauncherTypesEnum.DASHBOARD:
                openLinkInNewTab(this.accountData.protectedSettings.appLauncher.dashboard.customUrl);
                break;

            case AppLauncherTypesEnum.CLIENT:
                this.chatApi.requestAction(
                    'appLauncherOpenLink',
                    this.accountData.protectedSettings.appLauncher.client.customUrl
                );
                break;

            default:
                break;
        }
    }

    sendObserverSMS(phone) {
        const roomId = this.roomData._id;
        const message = this.translate('SMS.OBSERVER_MESSAGE');

        if (!phone) {
            throw new Error('Invalid phone number');
        }

        return this.smsService.sendObserverInvitation({
            roomId: roomId,
            phone: phone,
            message
        });
    }

    sendObserverEmail(email) {
        const roomId = this.roomData._id;

        if (!email) {
            throw new Error('Invalid Email');
        }

        return this.db.Email.observerInvitation({
            data: {
                roomId,
                to: email,
                crid: this.customerId
            }
        });
    }

    openSelfServiceWarmTransferSummary() {
        if (this.selfServiceController) {
            this.selfServiceController.clickSummary();
        }
    }

    openModeSwitchConfirmationModal(item, currentMode) {
        const switchToSharingMode = utils.isModeSupportNativeVideoSharing(item.internalMode);
        const isCurrentModeSharingMode = utils.isModeSupportNativeVideoSharing(currentMode);

        if (switchToSharingMode !== isCurrentModeSharingMode) {
            this.modeSwitchModalController.show(item, currentMode);
        } else {
            this.leftBarController.clickToSwitchMode(item);
        }
    }

    modeSwitchHandleModalResponse(response, modes) {
        return response
            ? this.leftBarController.clickToSwitchMode(modes.newMode)
            : this.leftBarController.setCurrentMeetingMode(modes.currentMode);
    }

    openDeviceClassification(recognize = false) {
        if (this.isAssurantDeviceSet && recognize) {
            this.recognizeLoader = recognize;

            setTimeout(() => {
                if (this.recognizeLoader) {
                    this.recognizeLoader = false;
                    this.errorDialogModal.show('ERROR_DIALOG.VIEW.SERVER_IS_DOWN', this.dir);
                    this.eventLog.recognizeFail({err: 'something wrong with server'});
                }
            }, ENDING_TIMEOUT);
            this.eventLog.recognizeStart();
        }

        if (this.leftBarController && recognize) {
            this.leftBarController.selectAgentAssist();
        }

        this.shouldOpenDeviceClassification = this.isAssurantDeviceSet ? true : !this.shouldOpenDeviceClassification;
        this.$rootScope.safeApply();
    }

    openHelp() {
        this.helpBrowser.openHelp();
        this.$rootScope.safeApply();
    }

    setCurrentResource(resource) {
        this.currentResource = resource;
    }

    displaySaveInHistoryButton() {
        return this.offlineMode && get(this.preloadedImage, 'resource');
    }

    disabledSharingButton() {
        return get(this.busy, 'isBusy') || get(this.videoControlService, 'isActive');
    }

    disabledSendButton() {
        return (
            get(this.busy, 'isBusy') ||
            get(this.videoControlService, 'isActive') ||
            (this.clientDisconnected && !this.offlineRoom) ||
            (this.displayLoaderReconnecting() && this.enableIdleModeUsability)
        );
    }

    disabledSaveButton() {
        return (
            get(this.busy, 'isBusy') ||
            (this.annotateService && this.annotateService.isFresh() && !get(this.videoControlService, 'isActive'))
        );
    }

    disabledSpeedtestButton(resultsReady) {
        return (
            this.observe ||
            this.displayLoaderReconnecting() ||
            (get(this.chatApi, 'client.mode') === MeetingMode.screen && !this.isDesktopSharingSession) ||
            get(this.chatApi, 'client.mode') === MeetingMode.appSharing ||
            (!resultsReady && (this.endedTheMeeting || this.customerEndedTheMeeting))
        );
    }

    displayImagesManagementButtons() {
        return (
            (!this.observe &&
                (this.connected !== get(this.CONNECTION_STATUS, 'WAITING') || this.offlineRoom) &&
                get(this.annotateService, 'canvasReady')) ||
            (this.libraryTaggingMode && get(this.preloadedImage, 'resource'))
        );
    }

    displaySendButton() {
        return (
            !this.observe &&
            (this.connected !== get(this.CONNECTION_STATUS, 'WAITING') || this.offlineRoom) &&
            get(this.annotateService, 'canvasReady')
        );
    }

    displaySaveButton() {
        return (
            this.showSaveButton &&
            !this.observe &&
            (this.connected !== get(this.CONNECTION_STATUS, 'WAITING') || this.offlineRoom) &&
            (get(this.annotateService, 'canvasReady') || get(this.videoControlService, 'isActive'))
        );
    }

    displaySharingButton() {
        return (
            (get(this.preloadedImage, 'resource') && this.offlineMode && !this.libraryTaggingMode) ||
            (!this.observe &&
                this.connected !== get(this.CONNECTION_STATUS, 'WAITING') &&
                this.enableShareMenu &&
                !this.quickSharingModesEnabled &&
                get(this.annotateService, 'canvasReady'))
        );
    }

    isToggleEnabled_NewHeaderFooterLeftbar() {
        return (
            this.tsInterfaceHelper.useNewInterface && get(this.accountData, 'protectedSettings.newHeaderFooterLeftbar')
        );
    }

    get isDesktopSharingSession() {
        return (
            get(this.chatApi, 'client.mode') === MeetingMode.screen &&
            get(this.chatApi, 'client.clientType') === PlatformType.desktop_web
        );
    }

    get isHandshakeSuccessForCurrentMode() {
        const {inCameraApprovalDialog, isOnPreCameraApprovalScreen} = this.chatApi.client;
        const meetingMode = stateByMode(this.chatApi.client.mode, true);
        const isReviewingTOS = this.chatApi.isReviewingTOS;
        const isHandshakeSuccess =
            get(this.chatApi, 'client.' + meetingMode.handshakeProp) &&
            !isReviewingTOS &&
            !inCameraApprovalDialog &&
            !isOnPreCameraApprovalScreen;

        if (!isHandshakeSuccess) {
            trace.info(`handshake not completed. params- handshakeProp: ${get(
                this.chatApi,
                'client.' + meetingMode.handshakeProp
            )}
             isReviewingTOS: ${isReviewingTOS}, inCameraApprovalDialog: ${inCameraApprovalDialog}, isOnPreCameraApprovalScreen: ${isOnPreCameraApprovalScreen}`);
        }

        return isHandshakeSuccess;
    }

    _initDeviceClassification(room, accountData, tsDeviceClassificationService) {
        this.isDeviceClassificationReady = false;

        const {smartDeviceSetId, issuesAndSolutionsEnabled, issuesAndSolutions} = accountData.protectedSettings;

        if (!issuesAndSolutionsEnabled) {
            return;
        }

        const initParams = {
            flowType: room.flowType,
            roomId: room._id,
            accountId: accountData._id,
            deviceSetId: smartDeviceSetId,
            agentAssistSettings: {
                isEnabled: issuesAndSolutionsEnabled,
                ...issuesAndSolutions
            }
        };

        this.$scope.$on('$destroy', () => tsDeviceClassificationService.resetService());
        tsDeviceClassificationService
            .initService(initParams)
            .then(() => {
                this.isDeviceClassificationReady = true;
                this.displayNewSelfServiceSummary =
                    this.tsDeviceClassificationService.isWarmTransferRequested && this.enableSelfServiceInLeftBar;

                if (tsDeviceClassificationService.isSelfService) {
                    if (!this.selfServiceController && this.displayNewSelfServiceSummary) {
                        this.selfServiceController = new SelfServiceController(
                            this.translate,
                            get(this.tsDeviceClassificationService, 'analysisData.successfulSteps'),
                            get(this.tsDeviceClassificationService, 'analysisData.reportedField.warmTransferInitiator'),
                            get(this.tsDeviceClassificationService, 'analysisData.warmTransferStep')
                        );
                        this._updateSelfServiceData();
                    }

                    tsDeviceClassificationService.analysisOpen();
                }

                this.$rootScope.safeApply();
            })
            .catch((err) => console.error('Device classification failed to init', err));
    }

    onIdleClient() {
        this.chatApi.setStatus('notificationSmsInProgress', true);
        this.eventLog.idleNotification();
        this.idleClient.idleNotification = this.idleClient.waitForClientNotification;
        const {smsUseNumberLookup} = get(this.accountData, 'protectedSettings.smsSettings');
        const showSmsPreview = this.accountData.settings ? this.accountData.settings.showSmsPreview : true;

        const data = {
            phone: this.browserUtilsService.getFromLocalStorage('phoneNumber'),
            roomId: this.roomData._id.toString(),
            chromeDetection: get(this.accountData, 'protectedSettings.chromeDetection'),
            cameraModeOnly: this.cameraModeOnly,
            showSmsPreview: showSmsPreview,
            mobileClientURL: this.clientStartURL,
            gateway: get(this.roomData, 'fieldsForReports.gatewayUsedForInvite'),
            offline: false,
            useNumberLookup: smsUseNumberLookup,
            countryCode: this.browserUtilsService.getFromLocalStorage('countryCode'),
            audio: this.isSupportAudio
        };

        if (this.$localStorage.startWithAgentType) {
            data.startWithAgentType = this.$localStorage.startWithAgentType;
        }

        this.smsService.sendNotificationSms(data);
    }

    _updateMailTrigger() {
        const shareByEmail = get(this.accountData, 'settings.shareByEmail');

        if (
            shareByEmail &&
            shareByEmail.enabled &&
            (shareByEmail.customToEnabled || shareByEmail.customSubjectEnabled)
        ) {
            const prepareMailPart = (part) => {
                const dirtyPart = replace(part, new RegExp('{{crid}}', 'gi'), this.customerId);

                return encodeURIComponent(dirtyPart);
            };

            const customTo = shareByEmail.customToEnabled ? prepareMailPart(shareByEmail.customTo) : '';
            const customSubject = shareByEmail.customSubjectEnabled
                ? `subject=${prepareMailPart(shareByEmail.customSubject)}`
                : 'subject=Techsee%20Image';

            this.mailTrigger = `mailto:${customTo}?${customSubject}`;
        } else {
            this.mailTrigger = 'mailto:?subject=Techsee%20Image';
        }
        this.mailTrigger += '&body=Techsee%20History%20Image:%20';
    }

    _handleSimpleAPIMode(phone, roomId, customerId, countryCode) {
        const gotoEntry = (room, observe, sms) => {
            const normalizedUrl = TsUtils.replaceUrlDomain(DASHBOARD_URL, this.$window.location.hostname);
            const url = normalizedUrl
                .replace('{room}', room)
                .replace('{observe}', observe ? 'true' : '')
                .replace('{sms}', sms ? sms : '');

            this.$window.location.replace(url);

            if (ENV.dev) {
                this.$window.location.reload();
            }
        };

        if (roomId) {
            return gotoEntry(roomId, true, '');
        }

        if (customerId && !phone) {
            this.db.Rooms.byCustomerId({
                params: {
                    customerId: customerId
                }
            }).then(
                (rooms) => gotoEntry(rooms.data[0]._id, true, ''),
                () => this.goToInvite()
            );
        }

        if (phone) {
            // Creating Room...
            this.db.Rooms.create({
                customerNumber: phone,
                customerCountryCode: countryCode || 'none',
                customerId: customerId
            })
                .then((room) => {
                    // eslint-disable-next-line no-param-reassign
                    roomId = room._id;
                })
                .then(() => {
                    // Sending SMS...
                    this.db.PrimarySMS.create({
                        phone: phone,
                        roomId: roomId,
                        chromeDetection: false
                    }).then(
                        (sms) => {
                            let smsId = 'primary.' + sms._id;

                            if (sms.short) {
                                smsId += '.short';
                            }

                            gotoEntry(roomId, false, smsId);
                        },
                        () => {
                            const smsId = 'error.' + phone;

                            gotoEntry(roomId, false, smsId);
                        }
                    );
                });

            return;
        }
    }

    _displayRedirectToStoreGuidance(status) {
        if (status && status !== VersionStatus.INVALID) {
            return;
        }

        if (this.redirectToStoreController) {
            this.redirectToStoreController.updateVersion(status === VersionStatus.INVALID);

            return;
        }

        this.redirectToStore = true;
        const clientOs = get(this.chatApi, 'client.clientOs') || this.roomData.clientOs;
        const isClientIos = clientOs ? clientOs.toLowerCase().indexOf('ios') !== -1 : false;

        this.redirectToStoreController = new RedirectToStoreController(
            this.translate,
            isClientIos,
            this.domUtilsService,
            this.dir,
            this.tsInterfaceHelper.useNewInterface,
            status === VersionStatus.INVALID
        );

        this.db.Rooms.setReportedField(this.roomData._id, {
            data: {
                event: {
                    key: 'mirroringStatus',
                    value: {
                        type: 'customerRedirectedToAppStore',
                        OS: this.wizardState.platform
                    },
                    type: 'push'
                }
            }
        });

        if (this.embeddedDashboard) {
            setTimeout(() => {
                const message = this.$translate.instant('DASHBOARD_MINI.USER_REDIRECTED');

                this.setMessageAlert(message, OBSERVER_ALERT_COLOR, USER_REDIRECTED_TIMEOUT);
            }, USER_REDIRECTED_TIMEOUT);

            return;
        }

        this.stateHelper.safeGo('dashboard.store');
    }

    _initSimpleModals() {
        this.customerEndedTheMeetingModalCtrl = new SimpleModalController(
            'customer-ended-the-meeting-modal',
            this.translate('REACT.MODAL.CUSTOMER_ENDED_THE_MEETING.TITLE'),
            this.translate('REACT.MODAL.CUSTOMER_ENDED_THE_MEETING.DESCRIPTION'),
            '',
            this.translate('REACT.MODAL.CUSTOMER_ENDED_THE_MEETING.CONFIRMATION')
        );
    }

    _initUiSettings() {
        this.$scope.$watch(
            () => get(this.tsDeviceClassificationService, 'countMessagesArrived'),
            () => {
                if (this.selfServiceController) {
                    const deviceName = get(this.tsDeviceClassificationService, 'deviceName');
                    const ledsMapping = get(this.tsDeviceClassificationService, 'analysisData.ledStatus');
                    const portsMapping = get(this.tsDeviceClassificationService, 'analysisData.cables');
                    const steps = get(this.tsDeviceClassificationService, 'analysisData.successfulSteps');

                    if (deviceName) {
                        this.selfServiceController.setDeviceType(deviceName);
                    }

                    if (steps) {
                        this.selfServiceController.setSteps(steps);
                    }

                    if (ledsMapping) {
                        this.selfServiceController.setLedsMapping(ledsMapping);
                    }

                    if (portsMapping) {
                        this.selfServiceController.setPortsMapping(portsMapping);
                    }

                    this.leftBarController.setSelfServiceSummaryButton();
                }
            }
        );

        // Init left bar config
        if (get(this.accountData, 'settings') && !this.offlineMode) {
            // Init left bar config
            this.showLeftBar = this.accountData.settings.showLeftBar;

            if (this.showLeftBar) {
                this.leftBarText = this.accountData.settings.leftBarText;
            }

            // Save button visibility
            this.showSaveButton = this.accountData.protectedSettings.showSaveButton;
        }

        this.$scope.$watchGroup(
            [
                () => this.displayImagesManagementButtons(),
                () => this.displaySendButton(),
                () => this.displaySaveButton(),
                () => this.displaySharingButton(),
                () => this.disabledSendButton(),
                () => this.disabledSaveButton(),
                () => this.disabledSharingButton(),
                () => this.displaySaveInHistoryButton()
            ],
            () => {
                if (this.topBarController) {
                    runInAction(() => {
                        this.topBarController.showImagesManagement = this.displayImagesManagementButtons();
                        this.topBarController.showSharingButton = this.displaySharingButton();
                        this.topBarController.showSaveButton = this.displaySaveButton();
                        this.topBarController.showSendButton = this.displaySendButton();
                        this.topBarController.showSaveInHistoryButton = this.displaySaveInHistoryButton();
                        this.topBarController.disabledSaveButton = this.disabledSaveButton();
                        this.topBarController.disabledSendButton = this.disabledSendButton();
                        this.topBarController.disabledSharingButton = this.disabledSharingButton();
                    });
                }
            }
        );
    }

    _allowIdleNotificationSms() {
        if (get(this.chatApi, 'dashboard.notificationSmsInProgress')) {
            this.chatApi.setStatus('notificationSmsInProgress', false);
            this.idleClient.idleNotification = this.idleClient.mobileNotConnectedNotification;
        }
    }

    _setTOSGuidance(appShare, screenShare) {
        if (appShare) {
            this.dashboardGuidanceController.setAppSharingTosGuidance();
        } else if (screenShare) {
            this.dashboardGuidanceController.setScreenShareTosGuidance();
        } else {
            this.dashboardGuidanceController.setTosGuidance();
        }
    }

    _clientDeviceDetails(deviceDetails) {
        const {clientOs, clientOsObject, deviceInfo, clientType, clientBrowser, clientDevice, usingApplication} =
            deviceDetails;

        runInAction(() => {
            if (clientBrowser) {
                this.dashboardState.clientMobileBrowser = clientBrowser;
            }

            if (clientDevice) {
                this.dashboardState.clientMobileType = clientDevice;
            }

            if (clientOs) {
                this.dashboardState.clientOs = clientOs;
            }

            this.dashboardState.sessionInfo.clientJoinedSession = true;
        });

        this.eventParams.device = deviceInfo;
        this.chatApi.client.deviceInfo = deviceInfo;
        this.chatApi.client.clientType = clientType;
        this.chatApi.client.clientOs = clientOs;
        this.chatApi.client.clientOsObject = clientOsObject;
        this.chatApi.client.usingApplication = usingApplication;

        if (usingApplication && !this.browserUtilsService.getFromSessionStorage('usingApplication')) {
            this.browserUtilsService.saveToSessionStorage('usingApplication', usingApplication);
        }

        if (!get(this.eventParams, 'device')) {
            return;
        }

        // finally set the platform for the wizard
        if (this.eventParams.device.match(/iOS|Windows/)) {
            this.wizardState.platform = 'apple';
        } else if (this.eventParams.device.match(/Android/)) {
            this.wizardState.platform = 'android';
        }

        // Save if the browser is chrome
        this.isChrome = this.eventParams.device.match(/Chrome/);
        this.localeHelper.updateLogs();
        this.chatApi.sendLog(this.STATUS_MESSAGES.BROWSER_DETECTED);
    }

    _bandwidthEventLog(samples, eventLogType) {
        const avgBandwidth = mean(samples);

        this.eventLog[eventLogType]({
            avgBandwidth: avgBandwidth,
            samples: samples
        });
    }

    _lowVideoBandWidthDetected(bandwidthSamples) {
        this._bandwidthEventLog(bandwidthSamples, 'lowVideoBandwidthDetected');

        if (this.chatApi.dashboard.showLowBandwidthMessage && !this.miniDashboard) {
            this.openOnVideoAlert('MAIN.VIEW.LOW_VIDEO_BANDWIDTH_DETECTED_ALERT');
            this.chatApi.setStatus('showLowBandwidthMessage', false, true);
        }

        if (this.embeddedDashboard) {
            this.chatApi.requestSwitchMode(FailureTriggerAction.lowBandwidthConnection);
        }

        this.chatApi.sendLog(this.STATUS_MESSAGES.LOW_VIDEO_BANDWIDTH_DETECTED);
        this.bandwidthLastSample = BANDWIDTH_LAST_SAMPLE.LOW;
    }

    _videoBandwidthStabilized(bandwidthSamples) {
        this._bandwidthEventLog(bandwidthSamples, 'videoBandwidthStabilized');
        this.chatApi.sendLog(this.STATUS_MESSAGES.VIDEO_BANDWIDTH_STABILIZED);
        this.bandwidthLastSample = BANDWIDTH_LAST_SAMPLE.STABLE;
    }

    _startMeasurement() {
        if (this.resetVideoBandwidthTest !== undefined) {
            this.resetVideoBandwidthTest = true;

            return;
        }

        this.resetVideoBandwidthTest = false;

        return this._calculateVideoBandwidth();
    }

    _calculateVideoBandwidth() {
        this.bandwidthMeasurementService.bandwidthParamCleanup(true);

        const bandwidthSamplingInterval = () => {
            if (this.resetVideoBandwidthTest) {
                this.bandwidthMeasurementService.bandwidthParamCleanup();
                this.bandwidthMeasurementService.flushBandwidthSampleBuffer();
                this.resetVideoBandwidthTest = false;

                return setTimeout(bandwidthSamplingInterval, this.timeToWaitToStartBandwidthMeasurement);
            }

            const clientMode = get(this.chatApi, 'client.mode');

            if (clientMode === MeetingMode.video && get(this.chatApi, 'client.visible') && !this.isPaused) {
                this.dashboardMediaService.getUserVideoStats(true).then((userVideoStats) => {
                    const bandwidthResult = this.bandwidthMeasurementService.calculateVideoBandwidth(userVideoStats);

                    if (bandwidthResult) {
                        switch (bandwidthResult.status) {
                            case BandwidthLevel.LOW_BANDWIDTH:
                                this.bandwidthMeasurementService.bandwidthSamplesCleanup();
                                this._lowVideoBandWidthDetected(bandwidthResult.bandwidthSamples);
                                break;

                            case BandwidthLevel.HIGH_BANDWIDTH:
                                this.bandwidthMeasurementService.bandwidthSamplesCleanup();
                                this._videoBandwidthStabilized(bandwidthResult.bandwidthSamples);
                                break;

                            default:
                                break;
                        }
                    }
                });
            }

            setTimeout(bandwidthSamplingInterval, this.videoBandwidthSamplingInterval);
        };

        setTimeout(bandwidthSamplingInterval, this.timeToWaitToStartBandwidthMeasurement);
    }

    _logObservationContinues() {
        const logInterval = () => {
            if (this.observe && this.observerDetails && this.connected === this.CONNECTION_STATUS.CONNECTED) {
                this.db.Observations.add({
                    data: {
                        roomId: this.roomData._id,
                        userName: this.currentUser.userName,
                        firstName: this.observerDetails.firstName,
                        lastName: this.observerDetails.lastName,
                        addNewSegment: false
                    }
                });
            }

            this.observeTimeout = setTimeout(logInterval, OBSERVATION_LOG_INTERVAL);
        };

        this.observableTimeout = setTimeout(logInterval, OBSERVATION_LOG_INTERVAL);
    }

    displayLoaderReconnecting(allowNotConnected) {
        // Product request - for old interface do not show loader on image upload / one-click modes
        if (
            (!this.tsInterfaceHelper.useNewInterface || this.chatApi.areBothSidesConnected) &&
            [MeetingMode.oneClick, MeetingMode.images].includes(get(this.chatApi, 'client.mode')) &&
            !this.enableIdleModeUsability
        ) {
            return false;
        }

        const isNotConnected = !get(this.chatApi, 'client.connected') || this.connected === this.CONNECTION_STATUS.IDLE;

        return (
            isNotConnected &&
            !allowNotConnected &&
            !this.endedTheMeeting &&
            !this.customerEndedTheMeeting &&
            !this.rejectedTermsAndConditions &&
            !this.libraryTaggingMode &&
            !this.offlineRoom &&
            !this.offlineMode &&
            !this.screenShareIOS
        );
    }

    displayWizardView() {
        const tosDisplayedOrAlreadyRejected =
            get(this.chatApi, 'isReviewingTOS') || get(this.chatApi, 'client.tosRejected');

        return (
            tosDisplayedOrAlreadyRejected &&
            !get(this.chatApi, 'client.isOnPreCameraApprovalScreen') &&
            (this.rejectedTermsAndConditions ||
                !this.idleMode ||
                (!this.displayLoaderReconnecting() && !this.endedTheMeeting))
        );
    }

    openOnVideoAlert(message) {
        const bandwidthModal = this.dialogFactory.show(DIALOG_TYPES.BANDWITH, message, this.dir);

        return bandwidthModal.result.then(({switchMode}) => {
            if (switchMode) {
                this.eventLog.lowBandwidthDialogResolution({
                    decision: this.allowOneClickPhotoMode
                        ? this.STATUS_MESSAGES.AGENT_SWITCHED_TO_PHOTO
                        : this.STATUS_MESSAGES.AGENT_SWITCHED_TO_IMAGE_UPLOAD
                });
                this.chatApi.requestSwitchMode(UserInteractionTriggerAction.bandwidthPopup);
            } else {
                this.eventLog.lowBandwidthDialogResolution({
                    decision: this.STATUS_MESSAGES.AGENT_CONTINUE_WITH_VIDEO
                });
            }
        });
    }

    photostreamBlockedAlert(message) {
        const photostreamBlockedModal = this.dialogFactory.show(DIALOG_TYPES.PHOTOSTREAM_BLOCKED, message, this.dir);

        return photostreamBlockedModal.result.then(({restartSession}) => {
            if (restartSession) {
                this._prepareToRedirectToInviteWithCustomerDetails();

                return this.goToInvite();
            }

            this.endMeeting(false, this.LogEvents.closedByTypes.dashboard);
        });
    }

    _getAddress(coords) {
        return this.db.Location.address({
            params: {
                lon: coords.lon,
                lat: coords.lat,
                lang: 'en_US',
                roomId: this.roomData._id
            }
        })
            .then((res) => {
                const loc = res.data[0];

                this.eventParams.address = loc.formattedAddress;
                this._updateAddressTranslation(LOCATION_ADDRESS_STATUS.AVAILABLE);
                this._updateTopbarAddress(coords, loc.formattedAddress);
                this.localeHelper.updateLogs();
                this.eventLog.clientLocationAddress({formattedAddress: loc.formattedAddress});
            })
            .catch(() => {
                // if retrieval fails, just show the coordinates
                this.eventParams.address = `${coords.lon}, ${coords.lat}`;
                this._updateAddressTranslation(LOCATION_ADDRESS_STATUS.FAILURE);
                this._updateTopbarAddress(coords);
                this.localeHelper.updateLogs();
                this.eventLog.clientLocationAddressError();
            })
            .finally(() => {
                if (this.embeddedDashboard) {
                    const data = {
                        x: coords.lon,
                        y: coords.lat,
                        address: this.eventParams.address || ''
                    };

                    if (coords.altitude) {
                        data.z = coords.altitude;
                    }

                    if (coords.accuracy) {
                        data.accuracy = coords.accuracy;
                    }

                    this.db.Rooms.customerGeolocation(this.roomData._id, {data});
                }
            });
    }

    _updateAddressTranslation(status) {
        switch (status) {
            case LOCATION_ADDRESS_STATUS.AVAILABLE:
                if (this.addressLocation.status === LOCATION_ADDRESS_STATUS.AVAILABLE) {
                    break;
                }

                this.addressLocation.status = LOCATION_ADDRESS_STATUS.AVAILABLE;
                this.addressLocation.icon = this.LOCATION_ICONS.AVAILABLE;

                if (this.isMobile || this.miniDashboard) {
                    this.mobilePopoverLocation = this.$translate.instant('DASHBOARD.VIEW.LOCATION_AVAILABLE', {
                        location: this.eventParams.address
                    });
                    this.miniPopoverLocation = this.mobilePopoverLocation;
                    this.addressLocation.popoverEnabled = true;
                    break;
                } else {
                    this.addressLocation.translation = 'DASHBOARD.VIEW.LOCATION_AVAILABLE';
                    break;
                }
            case LOCATION_ADDRESS_STATUS.FAILURE:
                if (this.addressLocation.status === LOCATION_ADDRESS_STATUS.FAILURE) {
                    break;
                }

                if (this.isMobile) {
                    this.addressLocation.popoverEnabled = false;
                }

                this.addressLocation.status = LOCATION_ADDRESS_STATUS.FAILURE;
                this.addressLocation.translation = 'DASHBOARD.VIEW.LOCATION_UNAVAILABLE';
                this.addressLocation.icon = this.LOCATION_ICONS.UNAVAILABLE;
                break;
            default:
                break;
        }
    }

    get isCopilotEnabled() {
        return get(this.accountData, 'protectedSettings.copilot.enabled');
    }

    async initCopilot(copilotElement) {
        if (!this.isCopilotEnabled) {
            return;
        }

        try {
            await this._copilotService?.init(copilotElement);

            this._copilotElement = copilotElement;

            this._copilotService.cameraPermissionSet(true);
            this._copilotService.startMeeting(this.roomData._id);
        } catch (err) {
            this.eventLog.failedToInitSophieSdk({err});
        }
    }

    _updateTopbarAddress(coords, formattedAddress = '') {
        const {lat, lon, altitude, accuracy} = coords;
        const googleMapsQuery = formattedAddress ? encodeURIComponent(formattedAddress) : `${lat},${lon}`;
        const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${googleMapsQuery}`;

        runInAction(() => {
            this.dashboardState.clientLocationInfo = {
                lat,
                lon,
                altitude,
                accuracy,
                formattedAddress,
                googleMapsLink,
                isDenied: false,
                isFailed: false
            };
        });
    }

    _topbarAddressFailure(isDenied) {
        runInAction(() => {
            if (isDenied) {
                this.dashboardState.clientLocationInfo.isDenied = true;
            } else {
                this.dashboardState.clientLocationInfo.isFailed = true;
            }
        });
    }

    videoStateChanged(isReady) {
        this._initTrafficLogger(isReady);
        this._initVideoDurationLogger(isReady);
        if (ENABLE_QOS) {
            this._initAgentQOS(isReady);
        }

        if (!isReady) {
            return;
        }

        this.chatApi.sendLog(this.STATUS_MESSAGES.VIDEO_READY);

        this.eventLog.videoReady();

        this.db.RoomsVideoReceived.update(this.roomData._id, {
            timestamp: new Date()
        });

        const clientMode = get(this.chatApi, 'client.mode');

        this.db.RoomsChangeAgent.update(this.roomData._id, {
            agent: clientMode ? clientMode.toUpperCase() : MeetingMode.video
        });

        if (!this.isIE || !this.isOpentok) {
            this._startMeasurement();
        }

        if (this.dashboardGuidanceController.isCameraApprovalShown) {
            this.dashboardGuidanceController.hide();
            this.eventLog.cameraApprovalGuidanceClosed({trigger: 'VIDEO_READY'});
        }

        // Cover case we missed media permission allow log
        this.cameraApprovalIndicationNotRequired = true;
    }

    _initTrafficLogger(enabled) {
        if (enabled && !this._trafficLogger) {
            this._trafficLogger = setInterval(() => this._logTraffic(), VIDEO_TRAFFIC_LOG_INTERVAL * 1000);
        }

        if (!enabled && this._trafficLogger) {
            clearInterval(this._trafficLogger);
            this._trafficLogger = null;

            this._logTraffic();
        }
    }

    _initVideoDurationLogger(enabled) {
        if (enabled && !this._videoDurationLogger) {
            this._videoDurationLogger = setInterval(() => this._logVideoDuration(), VIDEO_DURATION_LOG_INTERVAL * 1000);
        }

        if (!enabled && this._videoDurationLogger) {
            clearInterval(this._videoDurationLogger);
            this._videoDurationLogger = null;

            this._logVideoDuration();
        }
    }

    _initAgentQOS(enabled) {
        if (enabled && !this._agentQOSLogger) {
            this._agentQOSLogger = this.$interval(() => this._logAgentQOS(), AGENT_QOS_LOG_INTERVAL * 1000);
        }

        if (!enabled && this._agentQOSLogger) {
            this.$interval.cancel(this._agentQOSLogger);
            this._agentQOSLogger = null;

            this._logAgentQOS();
        }
    }

    _logAgentQOS() {
        if (this.isIE || this.isOpentok) {
            return this.$q((resolve) => resolve());
        }

        return this.dashboardMediaService
            .getUserVideoStats()
            .then((statReports) => {
                if (statReports.trackStats) {
                    return this.qos.sendQOS(statReports.trackStats);
                }
            })
            .catch((error) => {
                this.handleMediaLog(error, true);
            });
    }

    _logTraffic() {
        if (this.isIE && this.isOpentok) {
            return this.$q((resolve) => resolve());
        }

        return this.mediaStatsLogger.getTrafficDelta().then((delta) => {
            if (delta) {
                this.db.Rooms.setReportedField(this.roomData._id, {
                    data: {
                        event: {
                            key: 'videoTraffic',
                            value: delta,
                            type: 'inc'
                        }
                    }
                }).catch(() => {
                    this.mediaStatsLogger.restoreTrafficDelta(delta);
                });
            }
        });
    }

    _logVideoDuration() {
        return this.mediaStatsLogger.getVideoDurationDelta().then((delta) => {
            if (delta) {
                this.db.Rooms.setReportedField(this.roomData._id, {
                    data: {
                        event: {
                            key: 'videoDuration',
                            value: delta,
                            type: 'inc'
                        }
                    }
                }).catch(() => {
                    this.mediaStatsLogger.restoreVideoDurationDelta(delta);
                });
            }
        });
    }

    handleLog(eventLog) {
        this.$translate(eventLog.text, {
            device: get(this.eventParams, 'device'),
            address: get(this.eventParams, 'address')
        }).then((text) => {
            if (!text) {
                return;
            }

            this.lastEvent = {
                text,
                timestamp: new Date().getTime()
            };
        });
    }

    displayTimeoutDialog() {
        const modalInstance = this.$modal.open({
            animation: true,
            template: timeoutDialogView,
            controller: TimeoutDialogController,
            controllerAs: 'vm',
            resolve: {
                dir: () => this.dir
            }
        });

        modalInstance.result
            .then((keep) => {
                if (keep === 'yes') {
                    this.chatApi.keepSessionAlive();
                } else {
                    this.endMeeting(false, this.LogEvents.closedByTypes.dashboard, ReasonForClosing.timeout);
                }
            })
            .catch(() => false);
    }

    displayEndMeetingDialog() {
        this.$modal
            .open({
                animation: true,
                template: endMeetingDialogView,
                controller: EndMeetingDialogController,
                controllerAs: 'vm',
                windowClass: 'end-meeting-modal',
                resolve: {}
            })
            .result.then((confirmed) => {
                if (confirmed) {
                    this.endMeeting(false, this.LogEvents.closedByTypes.dashboard);
                }
            })
            .catch(() => null);
    }

    redirectToMain() {
        const redirectOptions = {};

        if (this.enableQuickLaunch && this.tsQuickLauncher.startedFromLauncher) {
            redirectOptions.sf = InviteStartedFrom.DESKTOP_LAUNCHER;
        }

        if (!this.embeddedDashboard) {
            this.stateHelper.safeGo('dashboard.main', redirectOptions);
        }
    }

    /**
     * Determnine visibility for the top bar. We want it always visible on the
     * dashboard.main and on the entry view, as soon as the customer connects.
     */
    isTopBarVisible() {
        return (
            this.tsInterfaceHelper.useNewInterface ||
            this.$state.$current.name === 'dashboard.main' ||
            this.connected !== CONNECTION_STATUS.WAITING
        );
    }

    mainFinishLoaded() {
        this.mainLoaded = true;
    }

    summaryEndSession() {
        this.timedOperation.lockAttempt(ENDING_TIMEOUT);
        this.completeEndMeeting(this.preventRedirection);
    }

    completeEndMeeting(preventRedirection, options) {
        if (
            !this.offlineRoom &&
            !this.offlineMode &&
            get(this.accountData, 'protectedSettings.feedbackSurveys') &&
            get(this.accountData, 'settings.enableSurveys')
        ) {
            return this._showSurvey(this._surveyCallback(preventRedirection));
        }

        if (this.returnQuery) {
            return this._goToLastSearch();
        }

        this._redirectOrCloseWindow(options);
    }

    _redirectOrCloseWindow(stateParams) {
        if (this.isIE && get(this.accountData, 'protectedSettings.closeWindowWhenSessionEnds')) {
            try {
                this.$window.open('', '_self').close();
            } catch (e) {
                this.goToInvite(stateParams);
            }
        } else {
            const redirectOptions = {...stateParams};

            if (this.enableQuickLaunch && this.tsQuickLauncher.startedFromLauncher) {
                redirectOptions.sf = InviteStartedFrom.DESKTOP_LAUNCHER;
            }

            this.goToInvite(redirectOptions);
        }
    }

    endMeeting(preventRedirection, closedBy, reason, options) {
        if (!this.timedOperation.lockAttempt(ENDING_TIMEOUT) || this.surveySubmitted) {
            return;
        }

        this.preventRedirection = preventRedirection;
        this.showLoader = false;
        this.endedTheMeeting = true;
        this.autoDataCollectionServices.end();

        if (!this.customerEndedTheMeeting) {
            this.jsApiService.sessionEnded({
                type: MessageType.sessionEnded,
                sessionId: this.roomData._id,
                techseeSessionId: get(this.roomData, 'guids')[0],
                customerNumber: this.customerNumber,
                customerId: this.customerId
            });
        }

        this._copilotService && this._copilotService.endMeeting(this.roomData._id);

        if (this.enableNetworkBandwidthMeasurement) {
            this.bandwidthMeasurementService.flushBandwidthSampleBuffer();
        }

        this._logTraffic()
            .then(() => this._logVideoDuration())
            .then(() => this.dashboardMediaService.clearService())
            .then(() => this.mediaStatsLogger.clearVideoLogging())
            .then(() => {
                const meeting = this.chatApi.dashboard.meeting;

                //TODO - CHECK: Need to verify that status arrives to server before socket is disconnected
                this.chatApi.setStatus('meeting', false);
                this.chatApi.disconnect();

                this.wizardHelper.removeState(this.wizardState);

                this.connected = CONNECTION_STATUS.DISCONNECTED;

                if (this.rejectedTermsAndConditions) {
                    this._prepareToRedirectToInviteWithCustomerDetails();
                }

                if (meeting && reason !== ReasonForClosing.forceTimeout) {
                    return this.roomData.end(closedBy, reason).catch((err) => console.error(err));
                }
            })
            .then(() => {
                if (this._shouldRouteToSessionEndedState()) {
                    return;
                }

                if (this.allowSessionSummary) {
                    this.eventLog.sessionSummary({
                        description: `Session summary is enabled. ${
                            this.hasScreenshots ? 'there are images' : 'skipping - no images'
                        }`
                    });
                    this._resetUnloadConfirmation();
                    this.timedOperation.release();

                    if (this.hasScreenshots) {
                        this.eventLog.sessionSummary({description: 'Showing session summary'});

                        return this.summaryService.showSummary();
                    }
                }

                return this.completeEndMeeting(preventRedirection, options);
            })
            .then(() => {
                this.helpBrowser.closeHelp();
                this.$rootScope.safeApply();
            })
            .catch(() => {
                this.timedOperation.release();
            });
    }

    onAbort(preventRedirection, closedBy) {
        if (this.offlineMode) {
            this.exitOfflineMode();

            return;
        }
        this.endMeeting(preventRedirection, closedBy);
    }

    _resetUnloadConfirmation() {
        this.$window.onbeforeunload = () => undefined;
    }

    _prepareToRedirectToInviteWithCustomerDetails() {
        this.$localStorage.lastUsedTOSNumberWithoutCountryCode = this.roomData.customerNumber.replace(
            this.roomData.customerCountryCode,
            ''
        );
        this.$localStorage.lastUsedTOSCountryCode = this.roomData.customerCountryCode;
        this.$localStorage.lastDeclineTOSCustomerId = this.roomData.customerId;
    }

    leaveOfflineRoom() {
        this.dashboardMediaService.clearService().catch(() => undefined);

        if (this.mediaStatsLogger) {
            this.mediaStatsLogger.clearVideoLogging().catch(() => undefined);
        }

        this.chatApi.disconnect();

        if (this.returnQuery) {
            return this._goToLastSearch();
        }

        this.goToInvite();
    }

    _surveyCallback(preventRedirection) {
        return (message) => {
            if (!preventRedirection) {
                if (message) {
                    return this._redirectOrCloseWindow({message});
                }

                this._redirectOrCloseWindow();
            } else if (message) {
                const modal = this.$modal.open({
                    animation: true,
                    template: thankYouView,
                    windowClass: 'thank-you-modal'
                });

                modal.result.catch(() => false);

                this.$timeout(3000).then(() => {
                    modal.close();
                });
            }
        };
    }

    _showSurvey(cb) {
        const settings = this.accountData.settings;
        const protectedSettings = this.accountData.protectedSettings;
        const newHeaderClass =
            this.tsInterfaceHelper.useNewInterface && get(this.accountData, 'protectedSettings.newHeaderFooterLeftbar')
                ? 'new-header-and-footer'
                : '';

        if (
            !settings ||
            !protectedSettings ||
            !protectedSettings.feedbackSurveys ||
            (protectedSettings.feedbackSurveys && !settings.enableSurveys)
        ) {
            return cb();
        }

        if (this._isFillingSurvey) {
            return;
        }
        this._isFillingSurvey = true;

        this.db.Rooms.find(`${this.roomData._id}/duration`, {bypassCache: true})
            .then((response) => {
                const now = new Date();
                const failure = response.duration < settings.failureSurveyThreshold;

                if (failure && !settings.enableFailureSurveys) {
                    return cb();
                }

                if (!failure && Math.random() > settings.surveyFrequency / 100) {
                    return cb();
                }

                const userId = this.roomData.userId;

                const createServey = this.db.Survey.create({
                    roomId: this.roomData._id,
                    // userId is either an id string or a data object (with an id entry)
                    userId: typeof userId === 'string' ? userId : userId._id,
                    creationTime: now,
                    type: failure ? SURVEY_CONSTANTS.TYPES.FAILURE : SURVEY_CONSTANTS.TYPES.QUALITY
                });

                return this.$modal
                    .open({
                        animation: true,
                        template: surveyView,
                        controller: SurveyController,
                        controllerAs: 'vm',
                        windowClass: `survey-modal ${newHeaderClass}`,
                        backdrop: 'static',
                        backdropClass: 'backdrop-hidden',
                        resolve: {
                            failure: () => failure,
                            questions: () =>
                                this.db.Survey.questions(this.accountData._id, {
                                    params: {
                                        surveyType: failure
                                            ? SURVEY_CONSTANTS.TYPES.FAILURE
                                            : SURVEY_CONSTANTS.TYPES.QUALITY
                                    },
                                    bypassCache: true
                                }).then((response) => response.data),
                            dir: () => this.dir
                        }
                    })
                    .result.then(
                        (answers) => {
                            this._endSurvey(createServey, answers, cb);
                        },
                        () => {
                            this._endSurvey(createServey, false, cb);
                        }
                    );
            })
            .catch(() => cb())
            .finally(() => {
                this._isFillingSurvey = false;
            });
    }

    _endSurvey(req, answers, cb) {
        this.surveySubmitted = true;

        const finalize = () => {
            if (answers) {
                return this.$translate('DASHBOARD.SURVEY.THANK_YOU').then((message) => cb(message));
            }

            return cb();
        };

        req.then((survey) => {
            const surveyId = survey._id;
            const toUpdate = {
                status: answers ? SURVEY_CONSTANTS.STATUSES.DONE : SURVEY_CONSTANTS.STATUSES.IGNORED
            };

            if (answers) {
                toUpdate.answers = answers;
            }

            const options = {
                data: toUpdate
            };

            return this.db.Survey.update(surveyId, options);
        })
            .then(() => finalize())
            .catch(() => finalize());
    }

    leaveMeeting() {
        this.dashboardMediaService.clearService().catch(() => undefined);
        this.mediaStatsLogger.clearVideoLogging().catch(() => undefined);
        this.chatApi.disconnect();
        this.connected = CONNECTION_STATUS.DISCONNECTED;

        this.goToInvite();
    }

    _goToLastSearch() {
        const params = this.returnQuery.split(',');
        // first parameter is the db query (customer Id or Number)
        const splittedParams = params[0].split(':');
        const key = splittedParams[0];
        let value = splittedParams[1];
        // the rest are used for the client side filter
        const filter = params.slice(1).join(',');

        if (key === 'state' && value === 'offline-pool') {
            return this.stateHelper.safeGo('invite.old.offlinePool');
        }

        if (key === 'customerId') {
            value = decodeURIComponent(value);
        }

        if (filter) {
            this.urlUtils.setParamValue('filter', filter);
        }

        const stateParams = {
            [key]: value,
            filter
        };

        if (this.returnQuery === 'searchByTags') {
            stateParams.isSearchByTags = true;
        }

        this.stateHelper.safeGo('invite.old.history', stateParams);
    }

    exitOfflineMode() {
        // The knowledge expert has no access to the rest of the
        // application so exiting the editor means logging-out
        if (this.libraryTaggingMode) {
            return this.logout();
        }

        if (this.returnQuery) {
            this._goToLastSearch();
        } else if (this.preloadedImage.resource) {
            this.db.HistoryImage.record(this.preloadedImage.resource._id)
                .then((record) => {
                    const customerId = record.data && record.data.customerId,
                        customerNumber = record.data && record.data.customerNumber;

                    if (customerId && customerId !== 'none') {
                        this.stateHelper.safeGo('invite.old.history', {customerId: customerId});
                    } else if (customerNumber) {
                        this.stateHelper.safeGo('invite.old.history', {customerNumber: customerNumber});
                    } else {
                        // shouldn't happen, but in any case...
                        this.goToInvite();
                    }
                })
                .catch(() => {
                    this.goToInvite();
                });
        } else {
            this.goToInvite();
        }
    }

    logout() {
        const accountId = this.accountData._id,
            userId = this.currentUser._id,
            returnURL = this.$rootScope.windowOrigin + BASE_PATH + get(this.accountData, 'protectedSettings.alias');

        return this.auth.logout(accountId, userId, returnURL).then((res) => {
            if (!res.data) {
                this.$window.location.href = '/';

                return;
            }

            this.$window.location.href = res.data;
        });
    }

    toggleTopBar(val) {
        this.topBarOpen = val;
    }

    closeLeftBar(val) {
        this.leftBarClosed = val;
    }

    toggleMeetingLink(val) {
        this.showMeetingLink = val;
    }

    selectDevice() {
        this.isSelectDevice = true;
    }

    _addDeviceClassificationTagsToHistory() {
        this.annotateService.setTags(this.deviceClassificationTags);

        if (this.preloadedImage.resource) {
            this._saveHistoryImageTags();
        }
    }

    displaySolution(solution) {
        const isCorrectIssue = solution && this.issueId === solution.issueId;
        const isTitleExist = get(solution, 'title');
        const isNewTag = every(this.deviceClassificationTags, (tag) => tag.text !== solution.title);

        const addSolutionTagsCondition = !this.isSelectDevice && isCorrectIssue && isTitleExist && isNewTag;

        if (addSolutionTagsCondition) {
            this.deviceClassificationTags.push({text: solution.title});

            this._addDeviceClassificationTagsToHistory();
        }
    }

    deviceIssueClicked(issue) {
        const isCorrectIssue = issue && this.deviceId === issue.deviceId;
        const isTitleExist = get(issue, 'image.title');
        const isNewTag = every(this.deviceClassificationTags, (tag) => tag.text !== issue.image.title);

        if (!this.isSelectDevice && isCorrectIssue && isTitleExist && isNewTag) {
            this.issueId = issue.issueId;
            this.deviceClassificationTags.push({text: issue.image.title});

            this._addDeviceClassificationTagsToHistory();
        }
    }

    deviceRecognized(device) {
        if (!this.isSelectDevice && !this.deviceId && isEmpty(this.deviceClassificationTags)) {
            this.deviceId = device.deviceId;
            this.deviceClassificationTags = [{text: device.name}, {text: device.model}];

            this._addDeviceClassificationTagsToHistory();
        }
    }

    analyzeClicked() {
        this.isSelectDevice = false;
        this.deviceId = null;
        this.issueId = null;
        this.deviceClassificationTags = [];
    }

    // eslint-disable-next-line no-empty-function
    sendImage(callback = () => {}) {
        if (this._sendingTimeout !== null) {
            return callback();
        }

        this.busy.busy();

        if (this.connected === CONNECTION_STATUS.DISCONNECTED && !this.offlineRoom) {
            this._sendingTimeout = this.$timeout(SENDING_TIMEOUT_INTERVAL);

            this._sendingTimeout.then(() => {
                this._sendingTimeout = null;

                if (this.connected === CONNECTION_STATUS.DISCONNECTED) {
                    this.$modal.open({
                        animation: true,
                        template: clientTimeoutDialogView,
                        controller: ClientTimeoutDialogController,
                        controllerAs: 'vm'
                    });

                    this.$modal.result.catch(() => false);

                    this.busy.free();
                    callback();

                    return;
                }

                this.sendImage();
                callback();
            });

            callback();

            return;
        }

        const img = this.annotateService.getObjectURL({
            useOriginalCanvas: true
        });

        if (!img) {
            callback();

            return this.busy.free();
        }

        this.sharingService.generateImageFileName(img).then(({blob, imgFileName}) => {
            this.isFromLibrary
                ? this.eventLog.dashboardSendingImage({library: true, mediaFileName: imgFileName})
                : this.eventLog.dashboardSendingImage({mediaFileName: imgFileName});

            const options = {fileName: imgFileName};

            this.sharingService.uploadRoomImage(
                blob,
                options,
                (url) => {
                    if (this.annotateService.isAnnotated()) {
                        this.chatApi.imageAnnotated();
                    }

                    const tags = this.annotateService.getTags();

                    this.chatApi
                        .sendImage(url, {
                            tags: tags,
                            sharedBy: senderTypes.DASHBOARD,
                            mediaFileName: imgFileName
                        })
                        .then(() => {
                            this.busy.free();
                            callback();
                        })
                        .catch(() => {
                            this.handleImageUploadFail();
                            callback();
                        });
                },
                () => {
                    this.handleImageUploadFail();
                    callback();
                }
            );
        });
    }

    saveResource() {
        if (!this.videoControlService.isActive) {
            const img = this.annotateService.getObjectURL();

            this.busy.busy();

            this.eventLog.savingResource();

            this.sharingService.uploadRoomImage(
                img,
                {isTemp: false},
                (url) => {
                    if (this.annotateService.isAnnotated()) {
                        this.chatApi.imageAnnotated();
                    }

                    this.chatApi.imageSaved();

                    const tags = this.annotateService.getTags();

                    this.chatApi.saveImage(url, {tags: tags});

                    this.busy.free();
                },
                () => this.handleImageUploadFail()
            );
        } else {
            this.busy.busy();

            const videoUrl = this.videoControlService.videoUrl;
            const tags = this.videoControlService.getTags();

            this.chatApi.saveVideo(videoUrl, {tags: tags});

            this.busy.free();
        }
    }

    sendImageEmail() {
        this.$window.open(this.mailTrigger);
    }

    saveImageCloud() {
        if (!this.preloadedImage.resource) {
            return;
        }

        // if only the tags are changed, don't save a new revision
        if (this.annotateService.history.isEmpty()) {
            return this._saveHistoryImageTags();
        }

        this.eventLog.savingHistoryImage();

        this._uploadAndSaveImage(
            (resourceId, {data}) =>
                this.db.S3.signHistory(`IMAGE/${this.preloadedImage.resource._id}`, {
                    data: {
                        accountId: this.accountData._id,
                        fileName: data.fileName,
                        fileType: data.fileType,
                        fileSize: data.fileSize
                    }
                }),
            this.db.HistoryImage.revision
        );
    }

    _saveHistoryImageTags() {
        const tags = this.annotateService.getTags();

        this.busy.busy();

        this.db.HistoryImage.update(this.preloadedImage.resource._id, {
            data: {tags}
        })
            .then(() => {
                this.busy.free();
            })
            .catch(() => this.busy.free());
    }

    handleImageUploadFail() {
        this.busy.free();
        this.miniDashboard
            ? (this.lastEvent = {text: this.miniDashboardImageUploadFailed})
            : this.errorDialogModal.show('ERROR_DIALOG.VIEW.IMAGE_UPLOAD_FAILED', this.dir);
    }

    _uploadAndSaveImage(signCallback, updateCallback) {
        this.busy.busy();

        const fileName = this._newRevisionFileName(),
            img = this.annotateService.getObjectURL(),
            tags = this.annotateService.getTags();

        if (img === null) {
            return;
        }

        const cbEnd = () => this.handleImageUploadFail(),
            cbSuccess = (url) =>
                updateCallback(this.preloadedImage.resource._id, {
                    data: {url, tags}
                })
                    .then((res) => {
                        if (this.preloadedImage.resource) {
                            this.preloadedImage.resource.url = res.data.url;
                            this.preloadedImage.resource.security = res.data.security;
                        }

                        return cbEnd();
                    })
                    .catch(cbEnd);

        this.sharingService.uploadImageS3(img, fileName, true, signCallback, cbSuccess, cbEnd);
    }

    saveImageLibrary() {
        const resourceId = get(this.preloadedImage, 'resource._id');

        if (!resourceId) {
            return;
        }

        if (this.annotateService.history.isEmpty()) {
            return this._saveLibraryImageTags(resourceId);
        }

        this.eventLog.savingLibraryImage();

        this._uploadAndSaveImage((resourceId, {data}) => {
            const url = this.preloadedImage.resource.url;
            const folder = this._getDirName(url);

            return this.db.S3.sign(data.fileName, {
                data: {
                    accountId: this.accountData._id,
                    fileType: data.fileType,
                    fileSize: data.fileSize,
                    folder: folder,
                    trimFolder: true
                }
            });
        }, this.db.Resource.revision);
    }

    _saveLibraryImageTags(resourceId) {
        const tags = this.annotateService.getTags();

        this.busy.busy();

        this.db.Resource.update(resourceId, {
            data: {tags}
        })
            .then(() => {
                this.busy.free();
            })
            .catch(() => this.busy.free());
    }

    /*
     * Takes the url of the file
     * Returns directory name of the file
     * @param url - String url of the file
     *
     * @return directory - String
     */
    _getDirName(url) {
        const path = url.substring(0, url.lastIndexOf('/'));
        // search for a single slash (/) (the match begins from the previous character
        // to make sure it's not a slash also, so the index we want is slashIndex + 2)
        const slashIndex = path.search(/[^\/]\/[^\/]/);
        const folder = slashIndex > 0 ? path.substring(slashIndex + 2) : null;

        if (!folder) {
            return null;
        }

        try {
            return decodeURIComponent(folder);
        } catch (e) {
            // URIError ("malformed URI sequence")
            return folder;
        }
    }

    _newRevisionFileName() {
        const url = urlParse(this.preloadedImage.resource.url, true),
            fileName = last(url.pathname.split('/')),
            extIndex = fileName.lastIndexOf('.'),
            baseName = fileName.substr(0, extIndex),
            extension = fileName.substr(extIndex),
            match = baseName.match(/(.*)(-rev)(\d+)$/),
            revName = match && match.length === 4 ? match[1] + match[2] + (Number(match[3]) + 1) : `${baseName}-rev1`;

        return revName + extension;
    }

    saveImageLocally(event) {
        const dataUrl = this.annotateService.getDataURL({useOriginalImage: this.isHighImageSharingQuality});

        if (this.isIE) {
            const blob = this.annotateService.dataUrlToBlob(dataUrl);

            // IE11 does not support data urls
            window.navigator.msSaveBlob(blob, 'img.jpg');
        } else {
            event.currentTarget.href = this.isHighImageSharingQuality
                ? this.annotateService.dataUrlToObjectUrl(dataUrl)
                : dataUrl;
        }
    }

    getFullName() {
        return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
    }

    getPreloadedTitle() {
        if (this.offlineMode) {
            if (this.preloadedImage && this.preloadedImage.resource) {
                return this.preloadedImage.resource.title;
            }

            if (this.preloadedVideo && this.preloadedVideo.resource) {
                return this.preloadedVideo.resource.title;
            }
        }
    }

    isQuickShareEnabled() {
        return this.tsInterfaceHelper.useNewInterface && this.enableShareMenu && this.quickSharingModesEnabled;
    }

    onDownloadClicked(data) {
        this.sharingService.download(data);
    }

    downloadResources(resourceType, allResources, downloadAndEmailNotification) {
        const data = this.selectedResources[resourceType],
            downloadNotification =
                Array.isArray(data) && data.length > 1
                    ? 'DASHBOARD.VIEW.RESOURCES_DOWNLOAD_SUCCESSFULLY'
                    : 'DASHBOARD.VIEW.DOWNLOAD_SUCCESSFULLY',
            text = downloadAndEmailNotification ? downloadAndEmailNotification : downloadNotification;

        forEach(data, (selectedResource) => {
            this.sharingService.download(selectedResource).catch((err) => console.error('Error downloading: ' + err));
        });

        this._showResourcesSharedNotification(data, text);
        this.selectUnselectAllResources(resourceType, allResources, true);
    }

    downloadResourcesAndOpenEmail(resourceType, allResources) {
        const data = this.selectedResources[resourceType],
            downloadAndEmailNotification =
                Array.isArray(data) && data.length > 1
                    ? 'DASHBOARD.VIEW.RESOURCES_DOWNLOAD_AND_EMAIL'
                    : 'DASHBOARD.VIEW.DOWNLOAD_AND_EMAIL';

        this.downloadResources(resourceType, allResources, downloadAndEmailNotification);
        this.$window.open(this.mailTrigger);
    }

    copyResourcesToClipboard(resourceType) {
        if (this._assertResourcesQuota(resourceType)) {
            this.onCopyToClipboardClicked(this.selectedResources[resourceType]);
        }
    }

    sendResourcesByEmail(resourceType) {
        if (this._assertResourcesQuota(resourceType)) {
            this.onSendByEmailClicked(this.selectedResources[resourceType]);
        }
    }

    sendResourcesToClient() {
        if (this._assertResourcesQuota('images')) {
            this.busy.busy();

            return this.sharingService
                .sendImagesToClient(this.selectedResources.images)
                .then(() => this.busy.free())
                .catch(() => this.handleImageUploadFail());
        }
    }

    _assertResourcesQuota(resourceType) {
        const selectedResources = this.selectedResources[resourceType];

        if (selectedResources.length <= this.multipleImagesSharingQuota) {
            return true;
        }

        if (this.tsInterfaceHelper.useNewInterface) {
            this.dialogFactory.show(
                DIALOG_TYPES.NOTIFICATION,
                this.$translate.instant('DASHBOARD.VIEW.EXCEED_SHARE_RESOURCES', {
                    imageCount: selectedResources.length,
                    maxImageCount: this.multipleImagesSharingQuota
                }),
                this.dir,
                true
            );
        }
    }

    newTopbarHeaderDownload() {
        if ((this.topBarController || this.enableFloatingToolbar) && this.currentResource) {
            this.onDownloadClicked(this.currentResource);
        }
    }

    newTopbarHeaderCopyToClipboard() {
        if ((this.topBarController || this.enableFloatingToolbar) && this.currentResource) {
            this.sharingService.getSignedDataForImageSharing(this.currentResource).then((signedData) => {
                this.currentResource = assign(this.currentResource, {signedData: signedData});
                this.onCopyToClipboardClicked([this.currentResource]);
            });
        }
    }

    newTopbarHeaderEmail() {
        if ((this.topBarController || this.enableFloatingToolbar) && this.currentResource) {
            this.sharingService.getSignedDataForImageSharing(this.currentResource).then((signedData) => {
                this.currentResource = assign(this.currentResource, {signedData: signedData});
                this.onSendByEmailClicked([this.currentResource]);
            });
        }
    }

    onCopyToClipboardClicked(data) {
        this.sharingService.copyToClipboard(data).then(() => {
            const text =
                Array.isArray(data) && data.length > 1
                    ? 'DASHBOARD.VIEW.RESOURCES_COPY_SUCCESSFULLY'
                    : 'DASHBOARD.VIEW.COPY_SUCCESSFULLY';

            this._showResourcesSharedNotification(data, text);
        });
    }

    onSendByEmailClicked(data) {
        this.sharingService.sendByEmail(this.mailTrigger, data).then(() => {
            this._showResourcesSharedNotification(data, 'DASHBOARD.VIEW.IMAGE_COPYING_EMAIL');
        });
    }

    enableCopyToClipboard() {
        return this.accountData.protectedSettings.enableCopyToClipboard;
    }

    enableEmailImageSharing() {
        return this.accountData.protectedSettings.emailImageSharing;
    }

    enableLocalImageSaving() {
        return this.accountData.protectedSettings.localImageSaving;
    }

    shareDisabledByAllToggles() {
        return !this.enableCopyToClipboard() && !this.enableEmailImageSharing() && !this.enableLocalImageSaving();
    }

    enableFloatingToolbar() {
        return get(this.accountData, 'protectedSettings.enableSessionToolbar');
    }

    _showResourcesSharedNotification(data, notificationText) {
        if (this.tsInterfaceHelper.useNewInterface) {
            this.dialogFactory.show(DIALOG_TYPES.NOTIFICATION, this.$translate.instant(notificationText), this.dir);
        }
    }

    _findSelectedResource(selectedResources, resource) {
        return find(selectedResources, (selectedResource) => selectedResource.url === resource.url);
    }

    toggleResourceSelection(resource, resourceType) {
        const selectedResources = this.selectedResources[resourceType];

        const selectedResource = this._findSelectedResource(selectedResources, resource);

        if (selectedResource) {
            selectedResources.splice(selectedResources.indexOf(selectedResource), 1);

            return;
        }

        const newResource = {inProgress: true, _id: resource._id, url: resource.url, type: 'url'};

        selectedResources.push(newResource);

        Promise.resolve().then(() =>
            this.sharingService
                .getSignedDataForImageSharing(newResource)
                .then((signedData) => {
                    assign(newResource, {inProgress: false}, {signedData});
                })
                .catch(() => {
                    selectedResources.splice(selectedResources.indexOf(newResource), 1);
                })
        );
    }

    resourcesReadyToBeShared(resourceType) {
        const resources = this.selectedResources[resourceType];

        if (some(resources, (resource) => !resource.inProgress)) {
            return true;
        }

        return false;
    }

    selectUnselectAllResources(resourceType, allResources, unselectAll) {
        if (this.togglingInProgress) {
            return;
        }

        this.togglingInProgress = true;

        return Promise.resolve()
            .then(() => {
                const checkboxes = $(`.input-checkbox-${resourceType}`);
                const isAnyCheckboxUnselected = some(checkboxes, (checkbox) => !checkbox.checked);

                checkboxes.prop('checked', isAnyCheckboxUnselected);

                const allResourcesByType = allResources[resourceType];
                const selectedResources = this.selectedResources[resourceType];
                const isAnyResourceUnselected = some(
                    allResourcesByType,
                    (resource) => !find(selectedResources, (selectedResource) => selectedResource.url === resource.url)
                );

                return forEach(allResourcesByType, (resource) => {
                    const isSelected = find(
                        selectedResources,
                        (selectedResource) => selectedResource.url === resource.url
                    );

                    unselectAll
                        ? this.unselectAllSelectedResourcesToggle(isSelected, resource, resourceType)
                        : this.selectUnselectToggle(isSelected, isAnyResourceUnselected, resource, resourceType);
                });
            })
            .finally(() => {
                this.togglingInProgress = false;
            });
    }

    unselectAllSelectedResourcesToggle(isSelected, resource, resourceType) {
        if (isSelected) {
            // Unselect all
            this.toggleResourceSelection(resource, resourceType);
        }
    }

    selectUnselectToggle(isSelected, isAnyResourceUnselected, resource, resourceType) {
        // Flip selection if not checked and we're selecting all OR
        //                if     checked and we're unselecting all
        if ((!isSelected && isAnyResourceUnselected) || (isSelected && !isAnyResourceUnselected)) {
            this.toggleResourceSelection(resource, resourceType);
        }
    }

    areAllResourcesSelected(resourceType, allResources) {
        if (isEmpty(allResources[resourceType]) || isEmpty(this.selectedResources[resourceType])) {
            return false;
        }

        return allResources[resourceType].length === this.selectedResources[resourceType].length;
    }

    isResourceSelected(resource, resourceType) {
        return find(this.selectedResources[resourceType], (selectedResource) => selectedResource.url === resource.url);
    }

    handleMediaLog(details, isError) {
        if (isError) {
            if (this.isOpentok) {
                this.eventLog.opentokError(details);
            } else {
                this.eventLog.turnError(details);
            }

            return;
        }

        if (this.isOpentok) {
            this.eventLog.opentok(details);
        } else {
            this.eventLog.turn(details);
        }
    }

    goToInvite(stateParams) {
        if (this.embeddedDashboard) {
            if (this.observe) {
                return this.stateHelper.safeGo('invitationEnded');
            }

            if (this.isMobile || this.isTablet) {
                return this.stateHelper.safeGo('sessionEnded');
            }

            return;
        }

        this.stateHelper.safeGo('invite', stateParams);
    }

    _hidePointerAlert() {
        this.saveOnLocalStorage
            ? this.browserUtilsService.getFromLocalStorage(`hidePointerUnavailableAlert_${this.roomData._id}`)
            : this.browserUtilsService.getFromSessionStorage('hidePointerUnavailableAlert');
    }

    _dontShowPointerAlertAgain() {
        this.saveOnLocalStorage
            ? this.browserUtilsService.saveToLocalStorage(`hidePointerUnavailableAlert_${this.roomData._id}`, true)
            : this.browserUtilsService.saveToSessionStorage('hidePointerUnavailableAlert', true);
    }

    _handleAgentPointerAvailability() {
        const availabilityStatus = this.chatApi.client.livePointerUnavailable;

        if (availabilityStatus !== null && availabilityStatus !== this.isClientPointerUnavailable) {
            this.isClientPointerUnavailable = availabilityStatus;
        }

        if (this.isClientPointerUnavailable && !this._hidePointerAlert()) {
            this._dontShowPointerAlertAgain(true);
            const message = this.translate('DASHBOARD.VIEW.POINTER_UNAVAILABLE');

            this.setMessageAlert(
                message,
                POINTER_UNAVAILABLE_MESSAGE_ALERT_COLOR,
                POINTER_UNAVAILABLE_MESSAGE_ALERT_TIMEOUT
            );
        }

        return Promise.resolve();
    }

    _handleDesktopSharingAgentControlStatus() {
        const agentHasRemoteControl = get(this.chatApi, 'client.agentHasRemoteControl');

        this.isLivePointerRunning = get(this.chatApi, 'livePointer.connected');

        if (agentHasRemoteControl === this.agentHasRemoteControl) {
            return;
        }

        if (!agentHasRemoteControl) {
            this.chatApi.setStatus('agentRequestRemoteControl', false);
        }

        this.agentHasRemoteControl = agentHasRemoteControl;

        const toastMessageText = agentHasRemoteControl
            ? this.translate('DASHBOARD.DESKTOP_SHARING_REQUEST_APPROVED')
            : this.translate('DASHBOARD.DESKTOP_SHARING_REQUEST_STOPPED');

        const toastMessageColor = agentHasRemoteControl ? DS_CONTROL_REQUEST_APPROVED_COLOR : DS_CONTROL_STOPPED_COLOR;

        const toastMessageTimeout = agentHasRemoteControl
            ? DS_CONTROL_REQUEST_APPROVED_TIMEOUT
            : DS_CONTROL_STOPPED_TIMEOUT;

        this.setMessageAlert(toastMessageText, toastMessageColor, toastMessageTimeout);

        if (this.agentHasRemoteControl) {
            this.eventLog.desktopShareClientApprovedRemoteControl();
        } else {
            this.eventLog.desktopShareClientStoppedRemoteControl();
        }

        return Promise.resolve();
    }

    setInteractionSummarySessionSource(sessionSource) {
        if (sessionSource === SESSION_SOURCE.AI || sessionSource === SESSION_SOURCE.VJ) {
            return this.translate('REACT.INTERACTION.SUMMARY.INTERACTION.SUMMARY');
        }

        return sessionSource;
    }

    isEnableWifiScan() {
        // need to add a conditional for live 2.0 and on live session (not offline)
        return (
            !this.observe &&
            !!get(this.accountData, 'protectedSettings.enableWifiScan') &&
            get(this.chatApi, 'client.clientType') === PlatformType.mobile_native &&
            get(this.chatApi, 'client.mode') === MeetingMode.screen
        );
    }
}
