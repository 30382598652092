import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IVjHistoryController} from './controller';
import {observer} from 'mobx-react';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {SecondaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/secondary';
import {Table} from '@techsee/techsee-ui-common/lib/table';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {InteractionSummaryComponent} from '@techsee/techsee-ui-components/lib/interaction-summary';
import {ImageModal} from '@techsee/techsee-ui-components/lib/image-modal';

import './styles.scss';

export interface IVjHistoryView {
    controller: IVjHistoryController;
}

@observer
export class VjHistoryView extends TechseeBaseComponent<IVjHistoryView> {
    renderInternal(): JSX.Element {
        const {controller} = this.props;
        const {
            translate,
            selectSession,
            goToInvitePage,
            prevPage,
            nextPage,
            currentPage,
            totalPages,
            isNextAvailable,
            isPrevAvailable,
            selectedSession,
            vjList,
            startDate,
            endDate,
            getInteractionSummaryData,
            interactionSummaryTranslated,
            isWidgetOpen,
            getDataOnInitialLoad,
            isInteractionSummaryReady,
            getDateFormattedByAcc,
            getDateTimeFormattedByAcc,
            vjGetImage,
            closeModal,
            openModal,
            selectedImg
        } = controller;

        return (
            <div className='vj-history-state use-common-styles'>
                <div className='vj-history-head'>
                    {goToInvitePage && (
                        <div className='go-back' onClick={goToInvitePage}>
                            <Icon className='go-back-icon' iconName={'arrow-back'} />
                            <div className='go-back-text'>{translate('REACT.INTERACTION.SUMMARY.VJ.LIST.GO.BACK')}</div>
                        </div>
                    )}
                    <SimpleLabel className='title'>{translate('REACT.INTERACTION.SUMMARY.VJ.LIST.TITLE')}</SimpleLabel>
                    {startDate && endDate && (
                        <SimpleLabel>
                            {translate('REACT.INTERACTION.SUMMARY.VJ.LIST.FROM_TILL', {
                                from: getDateFormattedByAcc(startDate),
                                till: getDateFormattedByAcc(endDate)
                            })}
                        </SimpleLabel>
                    )}
                </div>
                <div className='vj-history-table'>
                    <Table bordered condensed hover responsive>
                        <thead>
                            <tr className='table-head'>
                                <th>{translate('REACT.INTERACTION.SUMMARY.VJ.LIST.START.TIME')}</th>
                                <th>{translate('REACT.INTERACTION.SUMMARY.VJ.LIST.END.TIME')}</th>
                                <th>{translate('REACT.INVITE.VIEW.CUSTOMER_REF.LABEL')}</th>
                                <th>{translate('REACT.INTERACTION.SUMMARY.VJ.LIST.VJ.NAME')}</th>
                                <th>{translate('REACT.INTERACTION.SUMMARY.VJ.LIST.IMAGES')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vjList.map((session) => (
                                <tr
                                    className={
                                        selectedSession.sessionId === session.sessionId ? 'selected-session' : ''
                                    }
                                    key={session.sessionId}
                                    onClick={() => selectSession(session)}>
                                    <td>{getDateFormattedByAcc(session.creationTimestamp)}</td>
                                    <td>{getDateFormattedByAcc(session.endTimestamp)}</td>
                                    <td>{session.customerId}</td>
                                    <td>{session.flowName}</td>
                                    <td>{session.visualJourneyTotalImagesUploaded}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className='vj-history-table-pagination'>
                    <div className='prev-button'>
                        <SecondaryButton onClick={prevPage} disabled={!isPrevAvailable}>
                            <Icon iconName={'arrow-back'} />
                            {translate('REACT.INTERACTION.SUMMARY.VJ.LIST.PREVIOUS')}
                        </SecondaryButton>
                    </div>
                    <div className='page-of-pages'>
                        <SimpleLabel>
                            {translate('REACT.INTERACTION.SUMMARY.VJ.LIST.PAGE_OF_PAGES', {
                                page: currentPage,
                                pages: totalPages
                            })}
                        </SimpleLabel>
                    </div>
                    <div className='next-button'>
                        <SecondaryButton onClick={nextPage} disabled={!isNextAvailable}>
                            {translate('REACT.INTERACTION.SUMMARY.VJ.LIST.NEXT')}
                            <Icon iconName={'arrow-next'} />
                        </SecondaryButton>
                    </div>
                </div>

                {isInteractionSummaryReady && (
                    <InteractionSummaryComponent
                        getInteractionSummaryData={getInteractionSummaryData}
                        isWidgetOpen={isWidgetOpen}
                        getDataOnInitialLoad={getDataOnInitialLoad}
                        translated={interactionSummaryTranslated}
                        getFormattedDate={getDateTimeFormattedByAcc}
                        vjGetImage={vjGetImage}
                    />
                )}

                {openModal && (
                    <ImageModal
                        imageURL={selectedImg.url}
                        onClose={closeModal}
                        quality={selectedImg.quality}
                        label={selectedImg.label}
                    />
                )}
            </div>
        );
    }
}
